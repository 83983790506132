:root {
  --black: black;
  --white: white;
  --bordergreen: #48b466;
  --bg: #57575782;
  --blue-link-color: #1a0dab;
  --background-login: #f1f1f180;
  --dark-grey: #b1b1b1;
  --jardi-orange: #e8522d;
  --light-coral: #f3807e;
  --lightred: #f3807e42;
  --bg-color: #e2ffea;
  --bg-orange: #ffb46e80;
  --border-orange: #ffdeba;
  --dark-orange: #ff850045;
  --text-grey: #333;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 12px;
  height: 12px;
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

body {
  color: #333;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 20px;
}

.container {
  background-image: linear-gradient(#f1f1f180, #f1f1f180);
  min-height: 100vh;
}

.login-container {
  background-image: none;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.login-block {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  display: flex;
}

.image {
  max-width: 300px;
  padding: 20px;
}

.jardiland-button {
  background-image: linear-gradient(#e8522d, #e8522d);
  width: 100%;
}

.font {
  font-family: Roboto, sans-serif;
}

.terract-connect {
  color: #0a221c;
  text-align: center;
  cursor: pointer;
  background-image: linear-gradient(#7de29f, #7de29f);
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: 400;
  display: flex;
}

.terract-connect.active {
  cursor: not-allowed;
  background-image: linear-gradient(#7de29f80, #7de29f80);
}

.text-span {
  margin-right: 20px;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  text-align: center;
  flex-direction: column;
  width: 260px;
  display: flex;
}

.utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.reset-password-container {
  background-image: linear-gradient(#f1f1f180, #f1f1f180);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.reset-password-block {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  padding: 30px;
}

.reset-password-title {
  width: 100%;
  height: 40px;
  margin-bottom: 10px;
}

.form, .form-2 {
  margin-bottom: 20px;
}

.header-container {
  background-image: linear-gradient(#fff, #fff);
  border-width: 1px;
  border-color: #e9e9e9;
  border-bottom-style: solid;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 64px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.image_2 {
  width: 80px;
  min-width: 40px;
}

.header-logo {
  float: left;
  justify-content: center;
  align-items: center;
  height: 64px;
  margin-left: 0;
  display: flex;
}

.div-block {
  float: right;
  height: 64px;
}

.header-action {
  float: none;
  clear: none;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  display: flex;
}

.header-nav {
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  align-items: stretch;
  height: 64px;
  margin-right: 0;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.header-user {
  justify-content: center;
  align-items: center;
  height: 64px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.header-user-logout {
  cursor: pointer;
  font-family: Fontawesome webfont, sans-serif;
}

.header-user-logout:hover {
  color: #e8522d;
  font-weight: 600;
}

.text-block {
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 20px;
}

.text-block:hover {
  color: #e8522d;
  text-decoration: none;
}

.text-block-2 {
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 20px;
}

.text-block-2:hover {
  color: #e8522d;
}

.header-display-name {
  direction: ltr;
  text-align: left;
  text-transform: none;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding-left: 20px;
  padding-right: 20px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  display: flex;
}

.text-block-3 {
  color: #333;
  text-transform: uppercase;
  cursor: pointer;
}

.text-block-3:hover {
  color: #e8522d;
}

.content {
  background-image: linear-gradient(#f1f1f180, #f1f1f180);
  justify-content: center;
  align-items: center;
  padding: 0 75px 25px;
  font-size: 14px;
  display: block;
}

.text-block-4 {
  text-align: left;
  text-transform: uppercase;
  font-size: 20px;
}

.text-block-5 {
  color: #333;
}

.jar-home-action {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  height: 200px;
  margin-top: 0;
  margin-left: 25px;
  margin-right: 25px;
  padding-top: 0;
}

.slots-action {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  justify-content: center;
  align-items: stretch;
  height: 420px;
  margin-bottom: 20px;
  padding: 20px 10px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  display: flex;
}

.action-separator {
  float: none;
  background-image: linear-gradient(#e8522d, #e8522d);
  justify-content: flex-start;
  width: 1px;
  height: 198px;
  margin-left: 1100px;
  margin-right: 0;
  padding-bottom: 0;
  padding-right: 0;
  display: block;
}

.form-block {
  height: 64px;
}

.select-store {
  text-align: left;
  text-transform: lowercase;
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #bbb;
  height: 25px;
  margin-top: 27px;
  margin-bottom: 0;
  margin-right: 0;
  padding: 0 10px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.slots-week-container {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  min-height: 500px;
  margin-top: 25px;
  margin-bottom: 0;
  padding: 15px;
  font-family: Roboto, sans-serif;
}

.slots-action-title {
  color: #3339;
  text-align: left;
  border-bottom: 1px solid #e9e9e9;
  width: 90%;
  padding-left: 0;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}

.slots-form {
  float: none;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  width: 50%;
  margin-bottom: 0;
  display: block;
}

.slots-select-week-input {
  width: 40%;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.slots-select-week {
  flex-flow: row;
  place-content: flex-end flex-start;
  align-items: center;
  padding: 5px 5px 5px 0;
  display: flex;
}

.slots-select-week-label {
  text-align: left;
  width: 55%;
  margin-right: 0;
  font-size: 14px;
}

.slots-week-hours {
  align-items: center;
  height: 70px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  display: flex;
}

.slots-weeks-hours-label {
  width: 40%;
  padding-right: 10px;
  font-size: 13px;
}

.slots-select-begin-slot {
  background-image: linear-gradient(#fff, #fff);
  width: 15%;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.slots-label-begin {
  text-align: left;
  width: 10%;
  margin-right: 0;
  padding-right: 5px;
}

.slots-label-end {
  text-align: right;
  width: 10%;
  margin-left: 0;
  margin-right: 0;
  padding-right: 5px;
}

.slots-select-cutoff {
  background-image: linear-gradient(#fff, #fff);
  width: 15%;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.slots-select-days {
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding: 5px 5px 5px 0;
  display: flex;
}

.slots-label-cutoff {
  width: 50%;
}

.slots-select-cutoff-input {
  width: 45%;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.slots-generate {
  background-image: linear-gradient(#e8522d, #e8522d);
  width: 40%;
  height: 30px;
  margin-right: 5%;
  padding: 0;
  font-family: Roboto, sans-serif;
}

.slots-generate:hover {
  background-image: linear-gradient(#e8522dbf, #e8522dbf);
}

.div-block-2 {
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  display: flex;
}

.box-action-sep {
  float: none;
  clear: none;
  height: 100%;
  display: inline;
}

.div-block-3 {
  display: inline-block;
}

.box-actions-sep {
  float: left;
  border: 1px solid #e9e9e9;
  width: 1px;
  height: 100%;
  margin-right: 30px;
  display: inline-block;
}

.div-block-4 {
  float: left;
  clear: none;
  width: 46%;
  height: 100px;
  display: block;
}

.div-block-5 {
  float: left;
}

.box-template-config {
  float: left;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  display: block;
}

.box-template-config-title {
  color: #3339;
  border-bottom: 1px solid #e9e9e9;
  align-items: flex-start;
  width: 95%;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  display: block;
}

.monday-check {
  margin-right: 10px;
  padding-left: 20px;
}

.tuesday-check, .wenesday-check, .thursday-check, .friday-check, .saturday-check, .sunday-check {
  margin-right: 10px;
}

.box-hour-slot {
  align-items: center;
  height: 70px;
  margin-top: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  display: flex;
}

.text-block-6 {
  width: 40%;
  padding-right: 10px;
  font-size: 13px;
}

.text-block-7 {
  width: 10%;
}

.hours-begin {
  background-image: linear-gradient(#fff, #fff);
  width: 15%;
  height: 25px;
  margin-bottom: 0;
  padding: 0 0 0 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.text-block-8 {
  text-align: right;
  width: 10%;
  padding-right: 5px;
}

.hours-end {
  background-image: linear-gradient(#fff, #fff);
  width: 15%;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.text-span-2 {
  color: #333333c4;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.text-block-9 {
  flex: none;
  margin-right: 20px;
}

.form-block-2 {
  height: 280px;
  margin-top: 0;
  margin-bottom: 0;
}

.div-block-6 {
  align-items: center;
  height: 70px;
  margin-top: 10px;
  padding: 0 5px 0 0;
  display: flex;
}

.except-input-date-begin {
  width: 125px;
  height: 25px;
  margin-bottom: 0;
  margin-right: 20px;
  padding: 0 0 0 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.except-input-date-begin.input-from {
  margin-left: 0;
}

.except-input-date-begin.supplier-config {
  margin-left: 20px;
  margin-right: 0;
}

.except-input-date-begin.supplier-config.form {
  margin-top: 5px;
  margin-left: 0;
  padding-top: 0;
}

.div-block-7 {
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  padding: 5px 5px 5px 0;
  display: flex;
}

.div-block-7.div8 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  height: 50px;
  margin-top: 5px;
  display: flex;
  position: absolute;
  inset: 0% 0% auto auto;
}

.except-hours-begin {
  background-image: linear-gradient(#fff, #fff);
  width: 100px;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.text-block-10 {
  margin-right: 20px;
}

.text-block-11 {
  margin-left: 20px;
  margin-right: 10px;
}

.except-hours-end {
  background-image: linear-gradient(#fff, #fff);
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.except-day {
  flex: none;
  margin-bottom: 0;
  margin-left: 20px;
  padding-left: 20px;
}

.generate-except {
  text-align: center;
  background-image: linear-gradient(#e8522d, #e8522d);
  width: 200px;
  height: 30px;
  margin-top: 5px;
  padding: 0;
  font-family: Roboto, sans-serif;
}

.generate-except:hover {
  background-image: linear-gradient(#e8522d, #e8522d);
}

.agenda-title {
  color: #3339;
  border-bottom: 1px solid #e9e9e9;
  font-size: 16px;
}

.agenda {
  border: 1px solid #e9e9e9;
  height: 940px;
  margin-top: 10px;
}

.agenda-hour-col {
  border-right: 1px solid #e9e9e9;
  width: 40px;
  height: 860px;
  display: inline-block;
}

.except-input-date-end {
  width: 125px;
  height: 25px;
  margin-bottom: 0;
  padding: 0 0 0 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.text-block-13, .text-block-14 {
  margin-right: 5px;
}

.div-block-9 {
  align-items: center;
  height: 50px;
  display: flex;
}

.div-block-10 {
  border-bottom: 1px solid #e9e9e9;
  height: 40px;
}

.agenday-days {
  border-bottom: 1px solid #e9e9e9;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 40px;
  display: flex;
}

.div-block-11 {
  border-right: 1px solid #e9e9e9;
  height: 40px;
  margin-left: 40px;
}

.checkbox {
  float: left;
  font-family: Fontawesome webfont, sans-serif;
}

.checkbox.checkbox-unchecked.checkbox-center, .checkbox.checkbox-checked.checkbox-center {
  float: none;
}

.days-monday {
  border-right: 1px solid #e9e9e9;
  width: 18%;
  height: 40px;
  display: inline-block;
}

.days-block {
  float: left;
  border-right: 1px solid #e9e9e9;
  justify-content: center;
  align-items: center;
  width: 14.285%;
  height: 40px;
  display: flex;
}

.days-block.days-tuesday {
  width: 14.3%;
}

.div-block-12 {
  width: 40px;
  height: 40px;
  display: inline-block;
}

.div-block-13 {
  float: left;
  width: 40px;
  height: 40px;
  display: inline-block;
}

.div-block-14 {
  height: 40px;
  display: inline-block;
}

.days-block-last {
  float: right;
  justify-content: center;
  align-items: center;
  width: 14.285%;
  height: 40px;
  display: flex;
}

.div-block-15 {
  flex-direction: row;
  align-items: stretch;
  height: 900px;
  display: flex;
}

.div-block-16 {
  border-right: 1px solid #e9e9e9;
  flex-direction: column;
  width: 40px;
  height: 900px;
  margin-top: 0;
  padding-top: 0;
  display: flex;
}

.div-block-17 {
  border-bottom: 1px solid #e9e9e9;
  flex-direction: column;
  width: 40px;
  height: 120px;
  padding: 5px 10px;
  display: flex;
}

.div-block-18 {
  border-top: 1px solid #e9e9e9;
  justify-content: center;
  align-items: center;
  height: 55px;
  display: flex;
}

.div-block-19 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 55px;
  display: flex;
}

.text-block-15, .text-block-16 {
  color: #333333b3;
  font-size: 12px;
}

.div-block-17-last {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  padding: 5px 10px;
  display: flex;
}

.agenda-col {
  border-right: 1px solid #e9e9e9;
  flex-direction: column;
  align-items: stretch;
  width: 14.285%;
  padding: 0 5px 5px;
  display: flex;
}

.agenda-col.col-1 {
  height: 900px;
}

.case-slot-1 {
  border: 1px solid #3339;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 48.75px;
  margin-top: 5px;
  margin-bottom: 0;
  padding: 0;
  display: flex;
}

.case-slot-2 {
  border: 1px solid #3339;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 212.5px;
  margin-top: 5px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
}

.case-slot-2.case-slot24 {
  height: 372px;
}

.case-slot-3 {
  height: 161.25px;
  padding: 5px;
}

.case-slot-1-disabled {
  background-image: linear-gradient(#ececec80, #ececec80);
  border: 1px solid #e9e9e9;
  height: 48.75px;
  margin-top: 5px;
}

.case-slot-2-disabled {
  background-image: linear-gradient(#ececec80, #ececec80);
  border: 1px solid #e9e9e9;
  height: 102.75px;
  margin-top: 5px;
}

.case-slot-2-disabled._3h {
  height: 153px;
}

.case-slot-2-disabled.allday {
  height: 850px;
}

.text-block-17 {
  text-align: center;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  display: block;
}

.slots-cap-input {
  width: 100px;
  height: 25px;
  margin-bottom: 0;
  padding: 0 0 0 5px;
  font-size: 12px;
}

.slot-cap {
  justify-content: center;
  align-items: center;
  font-size: 12px;
  display: flex;
}

.slot-cap-cmd {
  color: #333;
  padding-right: 5px;
  display: inline-block;
}

.slot-cap-total {
  padding-left: 5px;
}

.div-block-20 {
  border: 1px solid #3339;
  justify-content: center;
  align-items: center;
  height: 102.75px;
  margin-top: 5px;
  display: flex;
}

.ruk-top-block {
  z-index: 0;
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  flex-flow: column;
  min-height: 800px;
  margin-top: 20px;
  padding: 20px;
  font-family: Roboto, sans-serif;
  display: flex;
  position: relative;
}

.ruk-top-block.jar-div {
  border-radius: 7px;
}

.ruk-bottom-block {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  height: 500px;
  margin-top: 20px;
  padding: 10px;
}

.ruk-top-1 {
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 0;
  display: flex;
}

.ruk-top-1.flex {
  justify-content: space-between;
}

.ruk-top-1.ruk-top-space-between {
  justify-content: center;
  display: flex;
}

.form-block-3 {
  margin-bottom: 0;
}

.input-search {
  width: 400px;
  height: 25px;
  margin-bottom: 0;
  margin-left: 0;
}

.input-search.commandinput {
  width: 100%;
  margin-bottom: 10px;
}

.search-submit {
  cursor: pointer;
  background-image: linear-gradient(#e8522d, #e8522d);
  flex: 0 auto;
  width: 200px;
  height: 35px;
  margin-left: 20px;
  margin-right: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  font-size: 14px;
}

.table-container {
  border: 1px solid #e9e9e9;
  margin-top: 10px;
  font-size: 14px;
}

.table-container.table-container-stock {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.table-header {
  background-image: linear-gradient(#f8f5f5, #f8f5f5);
  border-bottom: 1px solid #e9e9e9;
  flex-direction: row;
  justify-content: flex-start;
  height: 50px;
  display: flex;
}

.table-header.table-tr-stock {
  background-image: linear-gradient(#f8f5f5, #f8f5f5);
  height: 25px;
}

.table-head-1 {
  float: left;
  border-right: 1px solid #e9e9e9;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 16.66%;
  height: 50px;
  display: flex;
}

.table-head-col {
  float: left;
  border-right: 1px solid #e9e9e9;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.table-head-col.col-1 {
  flex: 0 auto;
  width: 5%;
  display: flex;
}

.table-head-col.col-1.table-cell, .table-head-col.col-1.cell {
  height: 60px;
}

.table-head-col.col-2 {
  flex: 0 auto;
  width: 17%;
}

.table-head-col.col-2.table-cell, .table-head-col.col-2.cell {
  height: 60px;
}

.table-head-col.col-3 {
  flex: 0 auto;
  width: 22%;
}

.table-head-col.col-3.cell {
  height: 60px;
}

.table-head-col.col-5 {
  flex: 0 auto;
  width: 17%;
}

.table-head-col.col-5.cell {
  height: 60px;
}

.table-head-col.col-6 {
  flex: 0 auto;
  width: 17%;
}

.table-head-col.col-6.cell {
  text-align: center;
  align-items: center;
  height: 60px;
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

.table-head-col.col-last {
  flex: 0 auto;
  width: 5%;
}

.table-head-col.col-last.cell {
  color: #333;
  cursor: pointer;
  height: 60px;
  text-decoration: none;
}

.table-head-col.col-last.cell:hover {
  color: #e8522d;
}

.table-head-col.col-last.cell.height {
  height: 80px;
}

.table-head-col.col-last.tab-cell.shipments-fontawesome, .table-head-col.col-last.tab-cell.orders-bad-fontawesome {
  color: var(--black);
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.col-7 {
  width: 10%;
}

.table-head-col.col-qty {
  flex-basis: 200px;
}

.table-head-col.sav-list-col-1 {
  width: 3%;
}

.table-head-col.sav-list-col-1.height {
  height: 80px;
}

.table-head-col.sav-list-col-2 {
  width: 10%;
}

.table-head-col.sav-list-col-2.height {
  height: 80px;
}

.table-head-col.sav-list-col-3 {
  width: 10%;
}

.table-head-col.sav-list-col-3.height {
  height: 80px;
}

.table-head-col.sav-list-col-4 {
  width: 13%;
}

.table-head-col.sav-list-col-4.height {
  height: 80px;
}

.table-head-col.sav-list-col-5 {
  width: 13%;
}

.table-head-col.sav-list-col-5.height {
  height: 80px;
}

.table-head-col.sav-list-col-6 {
  width: 10%;
}

.table-head-col.sav-list-col-6.height {
  height: 80px;
}

.table-head-col.sav-list-col-last {
  width: 3%;
}

.table-head-col.sav-list-col-last.height {
  height: 80px;
}

.table-head-col.sav-list-col-last.height.sav-fontawesome, .table-head-col.sav-list-col-last.tab-cell.sav-fontawesome {
  color: var(--black);
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.sav-list-col-8 {
  width: 15%;
}

.table-head-col.sav-list-col-8.height {
  height: 80px;
}

.table-head-col.sav-list-col-9 {
  width: 13%;
}

.table-head-col.sav-list-col-9.height {
  height: 80px;
}

.table-head-col.col-store, .table-head-col.col-status {
  flex-basis: 150px;
}

.table-head-col.sav-list-col-neworder {
  width: 10%;
}

.table-head-col.sav-list-col-neworder.height {
  height: 80px;
}

.table-head-col.stock-list-col {
  height: 25px;
}

.table-head-col.stock-list-col.stock-list-col-1, .table-head-col.stock-list-col.stock-list-col-2 {
  flex: 1;
}

.table-head-col.stock-list-col.stock-list-col-last {
  flex-shrink: 0;
  flex-basis: 30px;
}

.table-head-col.col-1 {
  flex: 0 auto;
  display: flex;
}

.table-head-col.col-1.cell {
  height: 60px;
}

.table-head-col.col-picker {
  flex: 1;
}

.table-head-col.col-4 {
  width: 17%;
}

.table-head-col.cc-col-1 {
  width: 3%;
  display: none;
}

.table-head-col.cc-col-2 {
  width: 10%;
}

.table-head-col.cc-col-2.tab-cell {
  height: 60px;
}

.table-head-col.cc-col-3 {
  width: 14%;
}

.table-head-col.cc-col-3.tab-cell {
  height: 60px;
}

.table-head-col.cc-col-4 {
  width: 19%;
}

.table-head-col.cc-col-4.tab-cell {
  height: 60px;
}

.table-head-col.cc-col-5 {
  width: 12%;
}

.table-head-col.cc-col-5.tab-cell {
  height: 60px;
}

.table-head-col.cc-col-6 {
  width: 10%;
}

.table-head-col.cc-col-6.tab-cell {
  height: 60px;
}

.table-head-col.cc-col-7 {
  width: 16%;
}

.table-head-col.cc-col-7.tab-cell {
  height: 60px;
}

.table-head-col.cc-col-8 {
  width: 16%;
}

.table-head-col.cc-col-8.tab-cell {
  width: 16%;
  max-width: none;
  height: 60px;
}

.table-head-col.cc-col-9 {
  width: 3%;
}

.table-head-col.cc-col-9.tab-cell.cc-font-awesome {
  color: var(--black);
  height: 60px;
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.shipments-col-1 {
  width: 5%;
}

.table-head-col.shipments-col-2 {
  width: 15%;
}

.table-head-col.shipments-col-3 {
  width: 20%;
}

.table-head-col.shipments-col-4, .table-head-col.shipments-col-5, .table-head-col.shipments-col-6 {
  width: 15%;
}

.table-head-col.shipments-col-7 {
  width: 10%;
}

.table-head-col.shipments-col-8 {
  width: 5%;
}

.table-head-col.shipments-col-8.tab-cell.shipments-fontawesome {
  color: var(--black);
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.sed-col-7.tab-cell.sed-fontawesome {
  color: #000;
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.badorder-col-1 {
  width: 0%;
  display: none;
}

.table-head-col.badorder-col-2 {
  width: 13%;
}

.table-head-col.badorder-col-3 {
  width: 20%;
}

.table-head-col.badorder-col-4 {
  width: 15%;
}

.table-head-col.badorder-col-5 {
  width: 20%;
}

.table-head-col.badorder-col-6 {
  width: 12%;
}

.table-head-col.badorder-col-7 {
  width: 15%;
}

.table-head-col.badorder-col-8 {
  width: 5%;
}

.table-head-col.badorder-col-8.tab-cell.badorder-fontawesome {
  color: var(--black);
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.list-checkbox-header {
  width: 3%;
}

.table-head-col.list-checkbox-header.height {
  height: 80px;
}

.table-head-col.list-header-col {
  width: 10%;
}

.table-head-col.list-header-col.height {
  height: 80px;
}

.table-head-col.list-header-col.list-example-filler {
  width: 14%;
}

.table-head-col.list-header-link {
  width: 3%;
}

.table-head-col.list-header-link.height {
  height: 80px;
}

.table-head-col.list-header-link.height.sav-fontawesome, .table-head-col.list-header-link.tab-cell.sav-fontawesome {
  color: var(--black);
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.table-head-col.list-checkbox {
  width: 3%;
}

.table-head-col.list-checkbox.height {
  height: 80px;
}

.table-head-col.sav-list-col-2-copy {
  width: 10%;
}

.table-head-col.sav-list-col-2-copy.height {
  height: 80px;
}

.table-head-col.list-col {
  width: 10%;
}

.table-head-col.list-col.height {
  height: 80px;
}

.table-head-col.list-col.tab-cell.list-example-filler {
  width: 14%;
}

.table-head-col.list-col-last {
  width: 3%;
}

.table-head-col.list-col-last.height {
  height: 80px;
}

.table-head-col.list-col-last.height.sav-fontawesome, .table-head-col.list-col-last.tab-cell.sav-fontawesome {
  color: var(--black);
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.row {
  border-bottom: 1px solid #e9e9e9;
  width: 100%;
  height: 60px;
  display: flex;
}

.row:hover {
  background-color: #f8f5f5;
}

.row.row-stock-list {
  height: 25px;
}

.row.waiting-instruction {
  background-color: #faac56;
}

.row.resume-preparation {
  background-color: #3db46d;
}

.row.cancel {
  background-color: #c7c7c7;
}

.row.height {
  height: 80px;
}

.row.collect-limit {
  background-color: #bf0a3a;
}

.row.pickup-limit {
  background-color: #eec416bf;
}

.table-row-1 {
  float: left;
  border-right: 1px solid #e9e9e9;
  justify-content: center;
  align-items: center;
  width: 10%;
  height: 60px;
  display: flex;
}

.table-row-col {
  float: left;
  border-right: 1px solid #e9e9e9;
  justify-content: center;
  align-items: center;
  width: 18%;
  height: 60px;
  display: flex;
}

.text-block-18 {
  margin-left: 50px;
}

.mode-delivery {
  width: 200px;
  height: 25px;
  margin-bottom: 0;
  margin-left: 0;
  padding: 0;
  font-size: 12px;
}

.step-pagination {
  flex: 0 auto;
  margin-bottom: 0;
  font-size: 14px;
}

.pagination {
  background-color: #ff850045;
  border-radius: 7px;
  flex: 0 auto;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.pagination-arrow {
  float: left;
  color: #333;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
  display: flex;
}

.pagination-arrow:hover {
  color: #fff;
  background-color: #e8522d;
}

.pagination-arrow.pag-active {
  color: #fff;
  text-transform: none;
  background-image: linear-gradient(#e8522d, #e8522d);
  font-size: 18px;
  font-weight: 700;
}

.pagination-arrow.inner-arrow-left, .pagination-arrow.inner-arrow-right {
  padding-left: 5px;
  padding-right: 5px;
}

.form-container {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.div-block-22 {
  width: 50%;
}

.div-block-22.text-center {
  text-align: right;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  padding-right: 20px;
  display: flex;
}

.text-block-19 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-block-20 {
  margin-left: 0;
  margin-right: 10px;
}

.ruk-top-3 {
  justify-content: flex-end;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.text-block-21 {
  text-align: center;
  flex: 0 auto;
  align-self: center;
  width: 50%;
}

.row-1 {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.classic-select {
  background-image: linear-gradient(#fff, #fff);
  width: 50%;
  height: 25px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.div-block-23 {
  justify-content: space-between;
  align-items: center;
  height: 50px;
  display: flex;
}

.form-info {
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
}

.form-info.form-error {
  background-image: linear-gradient(#f3807e42, #f3807e42);
  border: 1px solid #f3807e;
}

.form-info.form-success {
  background-image: linear-gradient(#e2ffea, #e2ffea);
  border: 1px solid #48b466;
}

.form-info.form-warn {
  background-image: linear-gradient(#ffb46e80, #ffb46e80);
  border: 1px solid #f07400bf;
}

.display-info-container {
  margin-left: auto;
  margin-right: auto;
}

.calendar-save-conf {
  background-image: linear-gradient(#ffb46e80, #ffb46e80);
  border: 1px solid #f07400bf;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  display: flex;
}

.text-block-22 {
  color: #e8522d;
}

.div-block-24 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.calendar-save-conf-disabled {
  background-image: linear-gradient(#e9e9e9, #e9e9e9);
  border: 1px solid #b1b1b1;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  display: flex;
}

.text-block-23 {
  color: #797979;
}

.global-link {
  color: #333;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.global-link:hover {
  color: #7de29f;
}

.text-field {
  width: 200px;
  padding-top: 0;
  padding-bottom: 0;
}

.step-pagination-wrapper {
  align-items: center;
  height: 100%;
  margin-left: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.div-block-26 {
  display: flex;
}

.col {
  border-right: 1px solid #e9e9e9;
  justify-content: center;
  align-items: center;
  width: 16.66%;
  padding: 5px;
  display: flex;
}

.col.colum-1 {
  border-right: 1px solid #e9e9e9;
  width: 18%;
}

.fcol {
  justify-content: center;
  align-items: center;
  width: 10%;
  display: block;
}

.column-0.fcol {
  border-right: 1px solid #e9e9e9;
  padding: 5px;
  display: flex;
}

.column-0.col {
  width: 16.66%;
}

.table-row-container {
  padding-bottom: 0;
  padding-right: 0;
}

.table-row-container.height {
  height: 80px;
}

.loader-wrapper {
  z-index: 10000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-wrapper.hidden {
  display: none;
  top: 0;
  bottom: 0;
  right: 0;
}

.text-block-24 {
  text-align: center;
  margin-top: 16px;
  font-size: 22px;
}

.div-block-28 {
  text-align: center;
  background-color: #fff;
  border-radius: 7px;
  padding: 15px;
}

.column-6 {
  flex-direction: column;
  align-items: center;
  padding-right: 0;
  display: block;
}

.commandinput {
  border-radius: 7px;
}

.column-7 {
  padding-left: 0;
}

.error-container {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
}

.div-block-29 {
  background-color: #fff;
  border-radius: 10px;
  flex-direction: column;
  min-width: 500px;
  max-width: 800px;
  padding: 30px;
  display: flex;
}

.heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 26px;
  font-weight: 700;
}

.image-2 {
  width: 200px;
}

.div-block-30 {
  text-align: center;
  padding-bottom: 20px;
}

.div-block-31 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.text-block-25 {
  font-size: 20px;
}

.col-title {
  text-align: center;
}

.checkbox-wrapper {
  text-align: center;
  cursor: pointer;
  margin-bottom: 0;
  padding-left: 0;
  font-size: 20px;
}

.hidden {
  display: none;
}

.table-cell {
  padding: 5px;
}

.table-cell.col-1 {
  border-right: 1px solid #e9e9e9;
}

.table-cell-content {
  width: 100%;
}

.text {
  text-align: center;
}

.text.remember-text {
  color: var(--white);
  -webkit-text-stroke-color: transparent;
  background-color: #0000;
  border: 1px solid #0000;
  width: 100%;
  min-width: 100%;
  font-size: 14px;
  font-weight: 500;
}

.pagination-pages {
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.pagination-number {
  color: #333;
  cursor: pointer;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.pagination-number:hover, .pagination-number.active {
  color: #fff;
  background-color: #e8522d;
}

.pagination-number.gam-color {
  background-color: var(--bordergreen);
}

.order-input, .search-form-wrapper {
  margin-bottom: 0;
}

.form-row {
  display: flex;
}

.input-wrapper {
  flex: 1;
  display: block;
}

.input-wrapper.select-store-type-input {
  flex: 0 auto;
  margin-right: 20px;
  display: block;
}

.input-wrapper.delivery-input {
  margin-right: 10px;
}

.input-wrapper.padder-input {
  margin-right: 20px;
}

.input-wrapper.filter-cmd-input {
  object-fit: fill;
  margin-left: 0;
  margin-right: -10px;
  padding-left: 0;
  padding-right: 0;
}

.input-wrapper.filter-by-date {
  display: block;
}

.input-wrapper.product-number-input {
  margin-left: 20px;
}

.input-wrapper.product-number-input.supplier-nb-product {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 31px;
  padding-top: 0;
  padding-left: 0;
}

.input-wrapper.search-client-input, .input-wrapper.select-delivery-input, .input-wrapper.select-batch-input, .input-wrapper.select-status-input {
  flex: 0 auto;
  display: block;
}

.input-wrapper.select-export-input {
  flex: 0 auto;
  max-width: 35vw;
  margin-right: 0;
  display: block;
}

.input-wrapper.select-store-input, .input-wrapper.select-store-types-input {
  flex: 0 auto;
  display: block;
}

.input-wrapper.client-input {
  flex: 0 auto;
  margin-right: 20px;
  display: block;
}

.input-wrapper.select-ramasse-type-input {
  flex: 0 auto;
  display: block;
}

.text-block-26 {
  padding-bottom: 1px;
  padding-left: 5px;
  font-weight: 500;
}

.action-row {
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.jar-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #e8522d;
  border-radius: 4px;
  justify-content: center;
  align-self: center;
  align-items: center;
  min-height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.jar-button.reset-input {
  justify-content: center;
  align-items: center;
  width: 35px;
  font-family: Fontawesome webfont, sans-serif;
}

.jar-button.search-button {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-transform: uppercase;
  margin-left: 0;
}

.jar-button.action-button.hidden {
  display: none;
}

.jar-button.action-button.force-collect {
  margin-right: 10px;
}

.jar-button.error-button {
  width: 200px;
}

.jar-button.z-to-ship.inactive, .jar-button.disabled {
  background-color: #d1d1d1;
}

.jar-button.jar-button-refund {
  margin-bottom: 10px;
}

.jar-button.jar-button-refund-shipping, .jar-button.jar-button-spacer {
  margin-right: 20px;
}

.jar-button.jar-button-spacer.gm-color {
  background-color: var(--bordergreen);
}

.jar-button.jar-button-spacer.badorder-special-button {
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-self: flex-end;
  margin-top: 10px;
  margin-right: 0;
}

.jar-button.product-qty-label {
  background-color: #2d9de8;
}

.jar-button.button-cc-bp-refund {
  margin-left: 20px;
}

.jar-button.button-cc-bp-submit, .jar-button.button-bonretrait-print {
  margin-right: 20px;
}

.jar-button.jar-dl-file {
  background-color: #4884b4;
}

.jar-button.jar-upload-file {
  background-color: #48b466;
  margin-left: 20px;
}

.jar-button.jar-button-invoice {
  margin-bottom: 10px;
}

.jar-button.jar-button-red {
  margin-top: 10px;
  margin-bottom: 10px;
}

.jar-button.jar-button-green {
  background-color: #48b466;
  margin-top: 10px;
  margin-bottom: 10px;
}

.jar-button.jar-button-blue {
  background-color: #4985f0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.jar-button.rebound-filter {
  margin-left: 20px;
}

.jar-button.export-button {
  flex-flow: row;
  flex: 0 auto;
  order: 0;
  place-content: center;
  align-self: auto;
  align-items: center;
  width: 100px;
  margin-top: 0;
  margin-left: 25px;
  margin-right: 20px;
  display: none;
}

.jar-button.export-button.gam-color {
  background-color: var(--bordergreen);
}

.jar-button.multi-action-button {
  display: none;
}

.jar-button.order-action-bdr-button, .jar-button.order-action-state-button, .jar-button.order-action-bdl-button, .jar-button.order-action-upload-button {
  height: 35px;
  padding-top: 0;
  padding-bottom: 0;
}

.pagination-row {
  justify-content: center;
  align-items: stretch;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.pagination-row-wrapper {
  position: relative;
}

.pagination-row-wrapper.badorders-pagination-row-wrapper {
  flex-flow: column;
  justify-content: center;
  display: flex;
}

.action-button-wrapper {
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.nav-tab {
  color: #333;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  display: flex;
}

.nav-tab:hover, .nav-tab.active {
  color: #e8522d;
}

.nav-title {
  text-transform: uppercase;
  flex: 0 auto;
  display: block;
}

.link {
  color: #333;
  justify-content: center;
  align-items: center;
  height: 60px;
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
  display: flex;
}

.link.unstyled {
  text-align: center;
  text-decoration: none;
}

.link.new-order-link, .link.batch-link, .link.collect-link-closed, .link.collect-link-opened {
  height: 30px;
  padding-right: 15px;
}

.select-field-2 {
  height: 35px;
}

.select-field-3 {
  width: 80px;
  height: 35px;
  margin-bottom: 0;
}

.form-block-4 {
  margin-bottom: 0;
}

.modal-wrapper {
  z-index: 1000000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
}

.modal-wrapper.hidden {
  display: none;
}

.modal-wrapper.modal-wrapper-confirmation {
  display: flex;
}

.modal-wrapper.modal-wrapper-confirmation.hidden {
  display: none;
}

.modal-container {
  background-color: #fff;
  flex-direction: column;
  width: 70%;
  min-height: 100px;
  display: flex;
}

.modal-container.modal-container-sav-pkgret {
  max-width: 80%;
}

.modal-title {
  padding: 20px;
}

.text-block-27 {
  font-size: 20px;
  font-weight: 700;
}

.text-block-28 {
  padding: 20px 10px 20px 20px;
}

.modal-actions {
  justify-content: space-around;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}

.collect-modal-wrapper {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
}

.collect-modal-wrapper.hidden {
  display: none;
}

.fro {
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

.suborders-input {
  max-width: 30vw;
  max-height: 10vw;
  display: block;
}

.error-modal-wrapper {
  z-index: 10000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
}

.error-modal-wrapper.hidden {
  display: none;
}

.batch-header {
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  display: block;
}

.link-block {
  align-items: center;
  display: flex;
}

.text-block-29 {
  font-family: Fontawesome webfont, sans-serif;
}

.text-span-3 {
  font-family: Fontawesome webfont, sans-serif;
  text-decoration: none;
}

.text-block-30 {
  color: #fff;
}

.text-block-31 {
  padding-right: 10px;
  font-family: Fontawesome webfont, sans-serif;
}

.div-block-32 {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.div-block-33 {
  display: flex;
}

.text-block-32 {
  padding-left: 10px;
}

.labels-container {
  z-index: 4;
  text-align: center;
  background-color: #ececec;
  border: 1px #b1b1b1;
  height: 100vh;
  padding-top: 10px;
  padding-left: 0;
  padding-right: 0;
  display: block;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 0 8px #000;
}

.labels-container.jar-labels-container {
  text-align: center;
  border-radius: 0;
  flex-flow: column wrap;
  align-items: center;
  padding-top: 10px;
  display: block;
  position: static;
  overflow: scroll;
  box-shadow: inset 0 0 8px #000;
}

.labels-container-wrapper {
  z-index: -2;
  background-color: #f4f4f4;
  border: 1px solid #f1f1f180;
  position: relative;
  box-shadow: inset 0 0 8px #000;
}

.label {
  background-color: #fff;
  width: 500px;
  min-height: 200px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}

.label.order-label {
  z-index: 2;
  float: none;
  clear: none;
  margin-top: 0;
  display: block;
  position: relative;
  overflow: visible;
}

.label.collect-label {
  z-index: 2;
  background-color: #fff;
  position: relative;
}

.div-block-34 {
  display: block;
  position: relative;
}

.barcode {
  justify-content: center;
  align-items: center;
  height: 80px;
  font-family: "Idautomationhc39m code 39 barcode", sans-serif;
  font-size: 20px;
  display: flex;
}

.div-block-35 {
  z-index: 2;
  justify-content: space-around;
  padding-top: 20px;
  display: flex;
  position: relative;
}

.text-block-33, .text-block-34, .text-block-35 {
  text-align: left;
}

.div-block-36 {
  padding-left: 10px;
}

.text-block-36 {
  font-style: italic;
}

.heading-2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 25px;
}

.heading-2.label-title {
  z-index: 0;
  text-align: left;
  padding-left: 20px;
  position: relative;
}

.heading-2.order-last-four {
  font-size: 38px;
  line-height: 44px;
}

.div-block-37 {
  z-index: 4;
  height: 10px;
  position: relative;
  box-shadow: inset 0 0 7px -1px #00000080;
}

.div-block-38 {
  z-index: 7;
  width: 100%;
  height: 100%;
  margin-top: -10px;
  position: absolute;
  box-shadow: inset 0 0 7px 1px #333;
}

.jar-input {
  border-radius: 5px;
  margin-bottom: 0;
}

.div-block-39 {
  z-index: 0;
  height: auto;
  position: static;
}

.div-block-40 {
  z-index: 4;
  clear: both;
  text-align: center;
  width: 99%;
  height: 0;
  margin-left: .5%;
  margin-right: .5%;
  position: relative;
  box-shadow: 0 -3px 7px 2px #00000063;
}

.div-block-40.container-shadow-top {
  width: 99%;
  height: 0;
  position: relative;
  box-shadow: 0 3px 7px 1px #00000087;
}

.link-block-2 {
  direction: ltr;
  text-align: center;
  cursor: pointer;
  flex: 1;
  font-style: normal;
  text-decoration: none;
  display: block;
}

.link-block-2.unstyled, .unstyledtext {
  text-align: center;
  text-decoration: none;
}

.action-div {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.errors-list {
  padding-right: 40px;
}

.cc-product-header {
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
}

.product-header-title {
  flex: 1;
  font-size: 16px;
}

.button-wrapper {
  float: right;
}

.product-line {
  align-items: stretch;
  font-size: 14px;
  display: flex;
}

.product-line.product-line-header {
  font-size: 16px;
}

.product-img-col {
  flex: 0 0 80px;
}

.product-description {
  flex: 1;
  align-self: center;
}

.product-qty-col {
  text-align: center;
  flex-shrink: 0;
  flex-basis: 90px;
  align-self: center;
  width: 90px;
  font-weight: 400;
}

.product-ean {
  font-weight: 400;
}

.product-details {
  display: none;
}

.product-ean-col {
  flex: 0 0 150px;
  align-self: center;
  width: 200px;
  font-weight: 700;
}

.product-img {
  max-width: 80px;
  max-height: 80px;
}

.assign-block {
  justify-content: center;
  display: flex;
}

.products-final-line {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
}

.product-qty-input-col {
  text-align: center;
  flex-shrink: 0;
  flex-basis: 200px;
  align-self: center;
  width: 200px;
}

.product-qty-input {
  margin-bottom: 0;
}

.submit-block {
  justify-content: center;
  margin-top: 50px;
  display: flex;
}

.order-last-four {
  padding-right: 20px;
  display: inline;
}

.label-order-title-container {
  flex-flow: row;
  place-content: stretch space-between;
  align-items: flex-start;
  display: flex;
}

.ccs-header {
  text-align: center;
  position: relative;
}

.ccs-header-title {
  margin-top: 0;
}

.collectable-switch {
  float: right;
  line-height: 30px;
  position: absolute;
  top: 0;
  right: 0;
}

.collectable-switch-link {
  text-decoration: underline;
}

.sub-header {
  background-color: #fff;
  flex: none;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.sub-header-item {
  color: #000;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
}

.sub-header-item.active {
  color: #e8522d;
}

.print-filter-row {
  justify-content: space-around;
  display: flex;
}

.sav-product-header {
  align-items: center;
  margin-top: -10px;
  display: flex;
}

.sav-action-bar-wrapper {
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.sav-pdt-tabs {
  height: 40px;
  padding-left: 20px;
  display: flex;
  position: relative;
  top: 1px;
}

.sav-pdt-tab {
  color: #333;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  margin-right: 3px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.sav-pdt-tab.sav-pdt-tab-active {
  background-color: #fff;
  border: 1px solid #ccc;
  border-bottom-color: #fff;
  font-weight: 700;
}

.sav-pdt-tabs-wrapper {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  margin-left: -20px;
  margin-right: -20px;
  position: relative;
}

.sav-product-header-title {
  flex: 1;
  margin-top: 0;
  margin-bottom: 10px;
}

.sav-block {
  border: 1px solid #ccc;
  flex: 1;
  margin: 10px;
  padding: 5px;
  display: flex;
}

.sav-block.sav-order-synthesis-pusher {
  opacity: 0;
  flex: 0 0 40%;
}

.sav-block.sav-block-no-border {
  border-style: none;
}

.sav-block.sav-block-no-border.sav-pkgret-block-title {
  border-top-style: solid;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.sav-block.block-hidden {
  opacity: 0;
}

.sav-block.sav-block-vertical {
  border-style: none;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
}

.sav-block.sav-block-neworder {
  flex-direction: column;
}

.sav-block.sav-block-batch, .sav-block.sav-block-collect {
  flex-direction: column;
  margin-top: 0;
}

.sav-block.sav-block-synthesis {
  border-style: none;
  margin: 0;
  padding: 0;
}

.sav-block.with-title {
  flex-direction: column;
}

.sav-block.info-header {
  flex-wrap: nowrap;
  margin-right: 10px;
}

.sav-sub-block {
  flex: 1;
  position: relative;
}

.sav-sub-block.discounts-retrait {
  padding-left: 20px;
}

.sav-descr-col-1.date-nb, .sav-block-line {
  display: flex;
}

.sav-block-line.hidden {
  display: none;
}

.sav-item-table {
  flex: 1;
  margin: -5px;
}

.sav-item-thead {
  background-color: #eee;
  padding: 2px 5px;
}

.sav-item-tbody {
  padding: 5px;
}

.sav-item-table-line {
  display: flex;
}

.sav-item-table-col.sav-col-2 {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-item-table-col.sav-col-3, .sav-item-table-col.sav-col-4, .sav-item-table-col.sav-col-5 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-item-table-col.sav-col-5.validate-col-cc {
  display: none;
}

.sav-item-table-col.sav-col-6, .sav-item-table-col.sav-col-7 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-item-table-col.sav-col-checkbox {
  width: 50px;
}

.sav-item-table-col.sav-col-8 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-item-table-col.sav-col-2 {
  flex: 0 auto;
  width: 60%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-item-table-col.sav-col-1 {
  width: 27%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-information-tab {
  display: block;
}

.jar-expedition-buttons {
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: flex-start;
  display: flex;
}

.sav-expedition-block {
  margin-top: 20px;
}

.sav-exp-col-1 {
  width: 50px;
  padding-left: 5px;
  padding-right: 5px;
  display: block;
}

.sav-exp-col-1.hidden {
  display: none;
}

.sav-exp-col-2 {
  width: 10%;
}

.sav-exp-col-3 {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-exp-col-4, .sav-exp-col-5, .sav-exp-col-6 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-exp-table-line {
  display: flex;
}

.sav-exp-checkbox-input {
  float: none;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-left: 0;
}

.sav-action-bar {
  display: flex;
}

.sav-action-bar.sav-pkgret-action-bar {
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: -20px;
}

.sav-action-bar.sav-action-bar-cc {
  justify-content: flex-start;
  display: flex;
}

.sav-information-block {
  margin-left: -10px;
  margin-right: -10px;
}

.sav-exp-qty-input {
  margin-bottom: 0;
}

.sav-search-form {
  flex-flow: row;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.sav-form-row {
  flex: 1;
  order: 0;
  display: block;
}

.sav-action-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: row;
  order: 1;
  justify-content: center;
  align-self: auto;
  align-items: flex-end;
  min-height: 100%;
  display: flex;
}

.product-qty-total {
  font-size: 20px;
}

.product-price-col {
  flex-shrink: 0;
  flex-basis: 80px;
  align-self: center;
  width: 90px;
  font-weight: 400;
}

.product-price-text {
  text-align: center;
}

.cc-bp-head-order-date {
  margin-top: 10px;
  margin-bottom: 10px;
}

.cc-bp-head-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

.product-qty-input-wrapper {
  display: flex;
}

.product-qty-validate {
  color: #fff;
  background-color: #2d9de8;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 50px;
  height: 38px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 18px;
  line-height: 38px;
}

.cc-search-form {
  position: absolute;
  top: 0;
  right: 0;
}

.bonretrait-line {
  display: flex;
}

.bonretrait-line.bonretrait-table-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}

.bonretrait-line.warning-wrapper {
  text-align: center;
}

.bonretrait-logo-col {
  flex-shrink: 0;
  flex-basis: 40%;
}

.bonretrait-address-block {
  flex: 1;
}

.bonretrait-logo-img {
  max-width: 40%;
}

.bonretrait-address {
  float: left;
}

.bontretrait-block {
  flex: 0 0 50%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.bonretrait-receipt-line {
  display: flex;
}

.bonretrait-receipt-line.bonretrait-receipt-line-total {
  font-weight: 700;
}

.bonretrait-receipt-v {
  text-align: right;
  flex: 1;
}

.bonretrait-thead {
  border: 1px solid #ccc;
}

.bonretrait-table {
  flex: 1;
}

.bonretrait-row {
  display: flex;
}

.bonretrait-col {
  padding: 10px;
}

.bonretrait-col.bonretrait-col-1 {
  text-align: center;
  flex-shrink: 0;
  flex-basis: 20%;
  align-self: center;
}

.bonretrait-col.bonretrait-col-2 {
  text-align: center;
  flex-shrink: 0;
  flex-basis: 30%;
  align-self: center;
}

.bonretrait-col.bonretrait-col-3 {
  text-align: center;
  flex-shrink: 0;
  flex-basis: 10%;
  align-self: center;
}

.bonretrait-col.bonretrait-col-4 {
  text-align: center;
  flex-basis: 10%;
  align-self: center;
}

.bonretrait-col.bonretrait-col-0 {
  text-align: center;
  flex: 0 5%;
  align-self: center;
  padding-left: 0;
}

.bonretrait-col.bonretrait-col-4-bis {
  text-align: center;
  flex: 0 10%;
  align-self: center;
}

.bonretrait-col.bonretrait-col-5 {
  text-align: center;
  flex: 0 20%;
  align-self: center;
}

.bonretrait-col.product-qty-input-col {
  flex-basis: 10%;
  display: block;
}

.jar-buttons {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.omc-header {
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}

.omc-title {
  margin-top: 0;
}

.button-list {
  position: absolute;
  top: 0;
  right: 0;
}

.omc-product-line {
  display: flex;
}

.omc-ean-col {
  flex-shrink: 0;
  flex-basis: 200px;
}

.omc-img-col {
  flex-shrink: 0;
  flex-basis: 90px;
}

.omc-price-col {
  flex-shrink: 0;
  flex-basis: 90px;
  align-self: center;
}

.omc-product-header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 5px;
  font-size: 16px;
}

.omc-product-description {
  flex: 1;
}

.product-qty-found-col {
  flex-shrink: 0;
  flex-basis: 90px;
  align-self: center;
}

.omc-col {
  padding-left: 5px;
  padding-right: 5px;
}

.omc-col.product-qty-found-col {
  text-align: center;
  flex-basis: 120px;
}

.omc-col.product-qty-col, .omc-col.omc-price-col {
  flex-basis: 120px;
}

.omc-col.omc-ean-col, .omc-col.omc-product-description {
  align-self: center;
}

.omc-col.product-qty-input-col.product-qty-col-final {
  font-size: 20px;
}

.omc-product-final-line {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
}

.product-header {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  padding-bottom: 5px;
}

.product-qty-final-checkbox {
  font-size: 20px;
  font-weight: 400;
}

.bonretrait-signature-block {
  margin-top: 80px;
}

.bonretrait-signature-block.pagebreakavoid {
  margin-top: 50px;
  padding-top: 30px;
}

.bonretrait-signature {
  float: right;
  border: 1px solid #000;
  height: 120px;
  margin-right: 50px;
  padding: 5px 10px 10px;
  display: inline-block;
}

.product-qty-wrapper {
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  display: block;
}

.product-shelf-col {
  flex-shrink: 0;
  flex-basis: 120px;
  align-self: center;
}

.store-config-header-title {
  flex: 1;
  margin-top: 0;
}

.store-config-header {
  display: flex;
}

.sconf-cc-mail-block, .sconf-cc-phone-block {
  padding-left: 10px;
}

.sconf-cc-mail-list {
  flex-direction: column;
  margin-left: -10px;
  margin-right: -10px;
}

.sconf-cc-input {
  flex: 1;
  margin-bottom: 0;
  display: inline-block;
}

.sconf-cc-mail-wrapper {
  width: 33%;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}

.sconf-cc-delete {
  background-color: #e8522d;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 38px;
  padding: 0 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 18px;
  line-height: 38px;
}

.sconf-cc-inner {
  align-items: center;
  display: flex;
}

.sconf-warehouse-wrapper {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.sconf-cc-add {
  background-color: #4985f0;
  padding: 9px;
}

.sconf-cc-title {
  flex: 1;
}

.sconf-cc-add-icon {
  padding-left: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 18px;
  display: inline-block;
}

.store-config-save {
  background-color: #48b466;
  padding-left: 9px;
  padding-right: 9px;
  font-size: 18px;
}

.sconf-cc-save {
  padding-left: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.cc-form-line {
  flex: 1;
  display: flex;
}

.cc-form-line.hidden {
  display: none;
}

.cc-search-form-block {
  flex: 1;
}

.cc-search-form-block.cc-search-form-action-block {
  flex: 0 auto;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-start;
  display: block;
}

.cc-form-date-range {
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding-right: 20px;
  display: block;
}

.cc-form-date-from {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.cc-form-date-to {
  margin-bottom: 0;
  margin-left: 10px;
}

.cc_form-action-row {
  align-items: flex-end;
  display: flex;
}

.cc-form-date-label {
  padding-left: 50px;
  font-weight: 500;
}

.cc-form-date-wrapper {
  align-items: center;
  display: flex;
}

.user-config-header {
  margin-bottom: 20px;
  display: flex;
}

.user-config-header-title {
  flex: 1;
  margin-top: 0;
}

.user-config-header-search {
  display: flex;
}

.uconf-input-wrapper {
  align-items: center;
  display: flex;
}

.uconf-search-store-col {
  flex: 1;
  margin-left: 10px;
}

.uconf-search-user-col {
  flex: 1;
  margin-right: 10px;
}

.uconf-search-form-wrapper {
  width: 100%;
}

.uconf-search-label {
  flex: 1;
}

.uconf-search-input {
  width: 70%;
  margin-bottom: 0;
  margin-left: 10px;
}

.uconf-search-buttons {
  justify-content: flex-end;
  margin-top: 10px;
  display: flex;
}

.uconf-result-title {
  margin-bottom: 0;
}

.uconf-result-list {
  padding-left: 10px;
}

.uconf-result-line {
  border-bottom: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.uconf-search {
  display: block;
}

.uconf-user-line {
  display: flex;
}

.uconf-user-line.uconf-user-first_name, .uconf-user-line.uconf-user-email {
  margin-bottom: 5px;
}

.uconf-user-label {
  flex: 1;
}

.uconf-user-input {
  width: 80%;
  margin-bottom: 0;
}

.uconf-user-role-list {
  padding-left: 10px;
}

.uconf-user-role-item {
  width: 33%;
  padding: 5px;
  display: flex;
}

.uconf-user-role-label {
  flex: 1;
  padding-right: 20px;
}

.uconf-user-role-title {
  margin-bottom: 0;
}

.uconf-user-store-list {
  padding-left: 10px;
}

.uconf-user-store-item {
  width: 50%;
  padding: 5px;
  display: flex;
}

.uconf-user-store-delete {
  color: #fff;
  background-color: #e8522d;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.uconf-user-store-select {
  width: 240px;
  margin-bottom: 0;
}

.uconf-user-store-select.shrink {
  flex: 0 auto;
}

.uconf-user-store-add-wrapper {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.uconf-user-store-add {
  background-color: #4985f0;
  padding: 9px;
}

.uconf-user-store-select-add {
  width: 30%;
  margin-bottom: 0;
  margin-right: 20px;
}

.uconf-user-save {
  background-color: #48b466;
  padding-left: 9px;
  padding-right: 9px;
  font-size: 18px;
}

.uconf-store-users-title-wrapper {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.uconf-store-users-item {
  width: 100%;
  padding: 5px;
  display: flex;
}

.uconf-store-users-item.uconf-store-users-item-body {
  border-bottom: 1px solid #ccc;
  align-items: center;
}

.uconf-store-users-unlink {
  background-color: #e8522d;
  font-family: Fontawesome webfont, sans-serif;
}

.uconf-store-users-acl {
  width: 20%;
}

.uconf-store-users-info {
  width: 60%;
}

.uconf-store-users-header {
  border-bottom: 1px solid #333;
  font-size: 16px;
}

.uconf-store-users-unlink-col, .uconf-store-users-detail-col {
  width: 10%;
}

.uconf-store-users-detail--link {
  padding-top: 9px;
  padding-bottom: 9px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.uconf-user-store-detail {
  padding: 9px 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.uconf-user-store-detail-wrapper {
  width: 250px;
  display: flex;
}

.uconf-user-store-detail-wrapper.hidden {
  display: none;
}

.uconf-user-store-detail-label {
  line-height: 40px;
}

.uconf-search-form-line {
  width: 100%;
  display: flex;
}

.uconf-actions {
  margin-bottom: 10px;
}

.add-new-user {
  background-color: #4985f0;
}

.uconf-user-delete {
  background-color: #e8522d;
  margin-right: 20px;
}

.uconf-title {
  margin-top: 0;
}

.uconf-search-col-inner {
  border: 1px solid #ccc;
  padding: 10px;
}

.uconf-search-col-title {
  padding-bottom: 5px;
  font-size: 16px;
}

.sav-search-form-block {
  text-align: center;
  flex: 1;
}

.sav-all-status-input {
  display: block;
}

.sav-form-line {
  display: flex;
}

.sav-form-line.sav-existing-file, .sav-form-line.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.sav-pkgret-title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
}

.sav-sub-block-wrapper {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.sav-pkgret-col-1 {
  font-weight: 500;
}

.sav-pkgret-col-2 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-pkg-block {
  margin-top: 20px;
  margin-bottom: 20px;
}

.sav-pkgret-notify-title, .sav-pkgret-create-title {
  margin-bottom: 10px;
  font-size: 18px;
}

.sav-pkgret-col-3 {
  flex: 1;
}

.sav-pkgret-col-4 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-pkgret-col-5, .sav-pkgret-col-6 {
  width: 15%;
  padding-left: 5px;
  padding-right: 5px;
}

.fontawesome {
  margin-left: 5px;
  font-family: Fontawesome webfont, sans-serif;
}

.fontawesome.bigicon {
  font-size: 20px;
}

.sav-existing-file-label, .sav-upload-file-label {
  flex: 1;
}

.sav-upload-file-input {
  flex: 1;
  margin-bottom: 0;
}

.shadow-block {
  box-shadow: 4px 0 10px 1px #0000009c;
}

.shadow-block.sav-expedition-block {
  padding-top: 5px;
}

.shadow-block.sav-pkg-block {
  padding-top: 5px;
  padding-bottom: 5px;
}

.sav-pkgret-form {
  margin-bottom: 0;
}

.sav-exp-col-7, .sav-exp-col-8 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-pkgret-col-7 {
  width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-newcmd-modal-wrapper {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
}

.sav-newcmd-modal-wrapper.hidden {
  display: none;
}

.sav-modal-content-text {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}

.sav-modal-form {
  margin-left: 10px;
  margin-right: 10px;
}

.sav-pkgret-col-input {
  width: 20%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-pkg-action-bar {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
}

.pkg-return-item {
  align-items: center;
  width: 50%;
  display: flex;
}

.pkg-return-select-label {
  width: 25%;
}

.pkg-return-select-input {
  flex: 1;
  margin-bottom: 0;
}

.sav-modal-wrapper {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
}

.sav-modal-wrapper.hidden {
  display: none;
}

.sav-block-neworder-content {
  width: 100%;
}

.sav-neworder-line {
  color: #333;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.sav-new-order-title {
  background-color: #eee;
  margin: -5px -5px 5px;
  padding: 5px;
}

.sav-neworder-wrapper {
  align-items: center;
  display: flex;
}

.sav-neworder-key {
  padding-right: 10px;
}

.sav-exp-col-refund {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.badorder-title {
  align-items: center;
  margin-bottom: -10px;
  display: flex;
}

.badorder-header-title {
  flex: 1;
  margin-top: 0;
}

.badorder-block {
  border: 1px solid #ccc;
  flex-flow: column;
  flex: 1;
  display: flex;
}

.badorder-block.badorder-raw-data, .badorder-block.badorder-reason, .badorder-block.badorder-special {
  margin-top: 20px;
}

.badorder-action-bar-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.badorder-action-bar {
  display: flex;
}

.badorder-block-line {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  display: flex;
}

.badorder-sub-block {
  flex-flow: column;
  flex: 1;
  display: flex;
}

.ship-search-form {
  display: flex;
}

.ship-form-date-label {
  text-align: center;
  padding-left: 50px;
  font-weight: 500;
}

.form-date-range {
  flex: 1;
  padding-right: 20px;
}

.form-date-from {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.form-date-to {
  margin-bottom: 0;
  margin-left: 10px;
}

.form-date-wrapper {
  align-items: center;
  display: flex;
}

.form-line {
  display: flex;
}

.search-form-block {
  flex: 1;
}

.form-action-row {
  display: flex;
}

.search-form-action-block {
  align-self: flex-end;
}

.return-pkg-action-bar {
  justify-content: flex-end;
  display: flex;
}

.pkg-return-comment-label {
  width: 25%;
}

.pkg-return-comment-wrapper {
  flex: 1;
}

.sav-log-block {
  margin-left: -10px;
  margin-right: -10px;
}

.sav-log-filters {
  align-items: center;
  width: 50%;
  display: flex;
}

.sav-log-filters-label {
  width: 25%;
}

.sav-log-list {
  margin: -5px;
}

.sav-log-filters-select {
  margin-bottom: 0;
}

.sav-log-synthesis {
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding-bottom: 5px;
}

.sav-batch-line {
  color: #333;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.sav-badorder-modal-wrapper {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0;
}

.sav-badorder-modal-wrapper.hidden {
  display: none;
}

.ustock-title {
  margin-top: 0;
}

.ustock-search-col-title {
  padding-bottom: 5px;
  font-size: 16px;
}

.ustock-search-col-inner {
  border: 1px solid #ccc;
  padding: 10px;
}

.ustock-search-col {
  flex: 1;
}

.ustock-search-buttons {
  justify-content: flex-end;
  margin-top: 10px;
  display: flex;
}

.ustock-input-wrapper {
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.ustock-search-label {
  flex: 1;
}

.ustock-search-input {
  width: 70%;
  margin-bottom: 0;
  margin-left: 10px;
}

.table-footer {
  background-color: #f8f5f5;
  display: flex;
}

.table-footer.table-tr-stock {
  height: 25px;
  margin-top: 20px;
}

.sav-form-date-label {
  padding-left: 0;
  font-weight: 500;
}

.sav-form-date-range {
  flex: 1;
  padding-right: 20px;
  display: block;
}

.sav-form-date-wrapper {
  align-items: center;
  display: flex;
}

.sav-form-date-from {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.sav-form-date-to {
  margin-bottom: 0;
  margin-left: 10px;
}

.sav-cc-pkgret-item {
  border: 1px solid #ccc;
  margin-bottom: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sav-cc-exp-col-1 {
  width: 50px;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-cc-exp-col-2 {
  width: 20%;
}

.sav-cc-exp-col-3 {
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-cc-exp-col-4, .sav-cc-exp-col-5, .sav-cc-exp-col-6 {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-cc-pkgret-col-1, .sav-cc-pkgret-col-2 {
  width: 10%;
}

.sav-cc-pkgret-col-3 {
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-cc-pkgret-col-4 {
  flex: 1;
}

.sav-pkgret-comment-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.sav-pkgret-comment-header {
  font-weight: 700;
}

.sav-cc-return-tab {
  color: #333;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  margin-right: 3px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.text-block-37 {
  color: #333;
  background-color: #eee;
  border-bottom: 1px solid #ccc;
  align-items: center;
  margin-right: 3px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.customer-header-container {
  background-image: linear-gradient(#fff, #fff);
  border-width: 1px;
  border-color: #e9e9e9;
  border-bottom-style: solid;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
}

.customer-header-logo {
  float: left;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 0;
  padding: 10px 5px;
  display: flex;
}

.customer-logo-img {
  height: 100%;
}

.customer-action-bar {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.jar-button-inner {
  height: 35px;
  line-height: 35px;
}

.sav-exp-col-reassort {
  width: 10%;
  padding-left: 5px;
  padding-right: 5px;
}

.sav-collect-title {
  background-color: #eee;
  margin: -5px -5px 5px;
  padding: 5px;
}

.sav-collect-head {
  color: #333;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.sav-collect-item {
  flex-direction: column;
  display: flex;
}

.sav-collect-body {
  border-left: 8px solid #eee;
  margin-left: 3px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 20px;
}

.sav-collect-line {
  display: flex;
}

.sav-collect-thead {
  border-bottom: 1px solid #ccc;
  display: flex;
}

.sav-collect-col-sku {
  flex-shrink: 0;
  flex-basis: 15%;
}

.sav-collect-col-title {
  flex-shrink: 0;
  flex-basis: 25%;
}

.sav-collect-col-qty-found, .sav-collect-col-qty-missing, .sav-collect-col-qty-missing-delayed {
  flex-shrink: 0;
  flex-basis: 20%;
}

.sav-block-synthesis-amount, .sav-block-synthesis-promo {
  border: 1px solid #ccc;
}

.sav-sub-block-synthesis {
  border: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

.sav-sub-block-synthesis.sav-block-synthesis-amount {
  margin-bottom: 20px;
}

.text-block-38 {
  border: 1px solid #ccc;
  padding: 5px;
}

.s-conf-warehouse-block {
  margin-top: 20px;
}

.s-conf-warehouse-is-warehouse {
  float: right;
  margin-bottom: 10px;
  padding-top: 10px;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
}

.heading-3 {
  display: inline-block;
}

.s-is-warehouse-input {
  float: right;
  margin-left: 20px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 24px;
}

.text-block-39 {
  float: left;
}

.sconf-warehouse-block {
  padding-left: 10px;
}

.sconf-warehouse-title {
  flex: 1;
}

.sconf-warehouse-list {
  flex-direction: column;
  display: inline-block;
}

.sconf-warehouse-ds-block {
  padding-left: 10px;
}

.sconf-warehouse-ds-title {
  flex: 1;
}

.sconf-warehouse-capa {
  display: flex;
}

.s-conf-warehouse-item-text {
  flex: 0 auto;
  min-width: 200px;
}

.sconf-warehouse-ds {
  display: flex;
}

.s-is-warehouse-ds-input {
  font-family: Fontawesome webfont, sans-serif;
  font-size: 24px;
}

.ruk-top-block-2 {
  z-index: 0;
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  min-height: 650px;
  margin-top: 20px;
  padding: 20px;
  font-family: Roboto, sans-serif;
  position: relative;
}

.ruk-top-block-2.flex-block {
  flex-direction: column;
  display: flex;
}

.form-row-2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: space-between;
  display: flex;
}

.text-block-40 {
  padding-bottom: 5px;
  padding-left: 5px;
  font-weight: 500;
}

.jar-button-2 {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #e8522d;
  border-radius: 4px;
  flex-direction: column;
  flex: 0 auto;
  justify-content: center;
  align-items: stretch;
  min-height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.jar-button-2.search-button {
  text-transform: uppercase;
  flex-direction: column;
  margin-left: 20px;
}

.jar-button-2.search-button.gam-color {
  background-color: var(--bordergreen);
}

.jar-button-2.reset-input {
  flex-direction: row;
  flex: 0 auto;
  align-self: auto;
  align-items: center;
  width: 35px;
  font-family: Fontawesome webfont, sans-serif;
  display: flex;
}

.jar-button-2.reset-input.gam-color {
  background-color: var(--bordergreen);
}

.jar-button-2.action-issue {
  flex: 0 auto;
  display: block;
}

.jar-button-2.action-issue.hidden {
  display: none;
}

.jar-button-2.action-prepare {
  display: block;
}

.jar-button-2.action-prepare.hidden {
  display: none;
}

.jar-button-2.action-sent {
  display: block;
}

.jar-button-2.action-sent.hidden {
  display: none;
}

.jar-button-2.jar-button-spacer {
  margin-right: 20px;
}

.jar-button-2.supplier-transport-save {
  flex-flow: column;
  align-content: space-around;
  align-items: center;
  margin-left: 10px;
  display: flex;
}

.jar-button-2.supplier-transport-save.hidden {
  display: none;
}

.jar-button-2.action-prepared {
  display: flex;
}

.jar-button-2.action-prepared.hidden {
  display: none;
}

.jar-button-2.error-button {
  width: 200px;
}

.jar-button-2.del-button.hidden {
  display: none;
}

.jar-button-2.disabled {
  background-color: var(--bg);
}

.jar-button-2.bon-ramasse-button {
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  width: auto;
  min-width: auto;
  height: 40px;
  margin-left: 0;
  display: none;
}

.jar-button-2.bon-ramasse-button.gam-color {
  background-color: var(--bordergreen);
}

.jar-button-2.print-button {
  text-transform: uppercase;
  flex-flow: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin-top: 26px;
  margin-left: 20px;
  display: flex;
  position: static;
}

.jar-button-2.print-button.gam-color {
  background-color: var(--bordergreen);
}

.jar-button-2.back-button {
  text-transform: uppercase;
  flex-flow: column;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  width: 25%;
  margin-top: 26px;
  margin-left: 20px;
  display: flex;
  position: static;
}

.jar-button-2.back-button.gam-color {
  background-color: var(--bordergreen);
}

.div-block-56 {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.export-orders {
  flex-direction: row;
  flex: 1;
  align-items: center;
  display: flex;
}

.date-text-prefix {
  width: 40px;
  padding-top: 9px;
  padding-left: 5px;
  padding-right: 15px;
}

.supplier-transport-info {
  width: 50%;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
}

.sav-descr-col-1-2 {
  align-items: center;
  display: block;
}

.supplier-modal-div {
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  padding: 5px;
  display: flex;
}

.supplier-modal-div.only-down {
  justify-content: flex-end;
  align-items: stretch;
  width: auto;
  display: flex;
}

.supplier-modal-div.comment-div {
  border: 1px solid #ccc;
  justify-content: space-between;
  width: 40%;
  margin: 5px;
  padding: 5px;
}

.supplier-modal-div.name-contact {
  align-items: flex-start;
  width: auto;
}

.supplier-modal-div.contact-type {
  justify-content: flex-start;
  align-items: flex-start;
  width: 30%;
}

.supplier-modal-div.comment-div-partial {
  border: 1px solid #ccc;
  justify-content: space-between;
  width: 40%;
  margin: 5px;
  padding: 5px;
}

.classic-select-2 {
  background-image: linear-gradient(#fff, #fff);
  width: auto;
  height: 25px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.classic-select-2.length-stock {
  flex: 0 auto;
  width: 15%;
  margin-top: 0;
  margin-left: 15px;
  padding-left: 0;
}

.classic-select-2.select-stock {
  width: 80px;
  margin-top: 0;
  margin-bottom: 0;
}

.retailer-full-empty-comment {
  flex: 1;
  margin-bottom: 0;
  display: block;
  overflow: visible;
}

.supplier-form-empty-stock, .sav-action-bar-2 {
  justify-content: space-between;
  display: flex;
}

.sav-item-table-line-2 {
  justify-content: space-around;
  display: flex;
}

.sav-item-table-line-2.reassort-product {
  flex-direction: row;
  display: flex;
}

.supplier-modal-incident {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 145vh;
  margin-top: 0;
  display: flex;
  position: absolute;
  inset: 0 auto auto 0;
}

.supplier-modal-incident.hidden {
  display: none;
}

.text-block-order-detail {
  margin-left: 5px;
  padding-left: 0;
}

.sav-item-tbody-2 {
  flex-direction: column;
  padding: 5px;
  display: block;
}

.supplier-modal-reassort-form {
  flex-direction: column;
  padding: 5px;
  display: flex;
}

.supplier-form-partial-stock {
  padding: 5px;
}

.supplier-form {
  flex-direction: column;
  display: flex;
}

.sav-block-2 {
  border: 1px solid #ccc;
  flex-direction: row;
  flex: 1;
  margin: 10px;
  padding: 5px;
  display: flex;
}

.sav-block-2.sav-block-invoice {
  flex-direction: row;
}

.sav-block-2.sav-block-neworder {
  flex-direction: column;
}

.sav-block-2.action-supplier {
  border-style: none;
}

.sav-product-header-title-2 {
  flex: 1;
  margin-top: 0;
  margin-bottom: 0;
}

.sav-product-header-2 {
  align-items: center;
  margin-top: -10px;
  margin-bottom: 10px;
  display: flex;
}

.div-block-51 {
  flex: 0 auto;
  width: 50px;
}

.modal-container-2 {
  background-color: #fff;
  flex-direction: column;
  width: 400px;
  min-height: 100px;
  margin-top: 0;
  display: flex;
}

.div-block-53 {
  width: 60%;
}

.supplier-form-label {
  padding-left: 10px;
}

.supplier-modal-header {
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 5px;
  display: flex;
}

.supplier-modal-tiltle {
  margin-bottom: 5px;
  padding-left: 0;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

.supplier-modal-div-block {
  flex: 1;
}

.supplier-modal-div-block.horizontal {
  justify-content: space-between;
  display: flex;
}

.supplier-form-block {
  flex: 1;
  width: 50%;
  margin-bottom: 0;
  display: block;
}

.text-block-41 {
  width: 80px;
  padding-left: 12px;
}

.order-detail-intel-div {
  margin-bottom: 10px;
  display: flex;
}

.div-block-52 {
  width: 27%;
}

.supplier-form-content {
  display: flex;
}

.supplier-action-order {
  flex-wrap: nowrap;
  flex: 0 auto;
  place-content: center space-around;
  align-items: center;
  width: 100%;
  display: flex;
}

.sav-sub-block-2 {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

.button-wrapper-2 {
  float: right;
  flex: 0 auto;
  display: block;
}

.div-block-54 {
  width: 180px;
  margin-left: 15px;
}

.icon-calendar {
  text-align: left;
  margin-left: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.icon-calendar.form {
  padding-top: 7px;
}

.supplier-delai-form-block {
  justify-content: flex-start;
  margin-bottom: 0;
  margin-left: 10px;
  display: flex;
}

.supplier-delai-form-block.col-6 {
  width: 50%;
}

.supplier-config-contact-list {
  flex-direction: column;
  height: auto;
  margin-bottom: 0;
  display: flex;
}

.supplier-table-header-div {
  border: 1px #000;
}

.supplier-table-header-div.col-2 {
  justify-content: center;
  align-items: center;
  width: 50%;
  display: flex;
}

.supplier-table-header-div.col-2.stock-date-header {
  width: 33%;
}

.supplier-table-header-div.col-2.rup-stock-libelle-header {
  width: 50%;
}

.supplier-table-header-div.col-1 {
  order: 0;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30px;
  display: flex;
}

.supplier-table-header-div.col-1.stock-filename-header {
  width: 33%;
}

.supplier-table-header-div.col-1.rup-stock-supp-ref-header {
  width: 50%;
}

.supplier-table-header-div.col-3 {
  flex: 0 auto;
  margin-left: 0;
  padding-left: 0;
}

.supplier-table-header-div.col-3.stock-time-header {
  width: 33%;
}

.supplier-config-stock-div.stock-rupture {
  flex: 0 auto;
  justify-content: flex-start;
  margin-left: 20px;
  padding-top: 10px;
  display: block;
}

.supplier-config-stock-div.stock-rupture-temp {
  width: auto;
  margin-left: 20px;
  padding-top: 10px;
}

.supplier-config-active-div {
  justify-content: flex-start;
  display: flex;
}

.supplier-config-stock-rupture {
  flex-direction: column;
  justify-content: space-around;
  width: 250px;
  display: flex;
  overflow: hidden;
}

.supplier-button-action {
  background-color: #e8522d;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin-top: 0;
  margin-bottom: 10px;
  padding: 5px 10px;
  font-size: 14px;
  display: flex;
}

.supplier-button-action.validation {
  flex-wrap: nowrap;
  align-items: center;
  width: 100px;
  font-size: 12px;
  display: flex;
}

.supplier-button-action.upload-btn {
  min-width: 170px;
  font-size: 12px;
}

.supplier-button-action.upload-btn.hidden {
  display: none;
}

.supplier-button-action.new-contact {
  min-width: 150px;
  margin-top: 10px;
  font-size: 12px;
}

.supplier-button-action.download-btn {
  min-width: 170px;
  font-size: 12px;
}

.supplier-button-action.download-btn.hidden {
  display: none;
}

.supplier-button-action.save-btn {
  margin-top: 10px;
  margin-right: 10px;
  padding: 9px 19px;
}

.supplier-button-action.add-date {
  margin-bottom: 0;
  margin-left: 20px;
  padding: 5px 8px;
  font-size: 12px;
}

.supplier-button-action.stock-upload-form-button {
  width: 100px;
  max-height: 35px;
  margin-bottom: 15px;
  margin-left: 20px;
}

.supplier-button-action.stock-upload-form-button.gam-color {
  background-color: var(--bordergreen);
}

.supplier-contact {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  height: 500px;
  margin-bottom: 20px;
  padding: 0 10px 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  display: flex;
}

.supplier-contact.hidden {
  display: none;
}

.supplier-config-stock-rupture-tab {
  border: 1px solid #000;
  min-width: 100px;
  max-width: 300px;
  height: 175px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
  overflow: scroll;
}

.supplier-config-stock-row {
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.supplier-table-content-div.col-1.cell {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-bottom: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  display: flex;
}

.supplier-table-content-div.col-2.cell, .supplier-table-content-div.col-3.cell {
  border: 1px solid #e9e9e9;
  border-top: 1px #000;
  border-left: 1px #000;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  display: flex;
}

.supplier-config-radio {
  margin-left: 10px;
  margin-right: 20px;
}

.supplier-modal-contact {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  inset: 0% auto auto 0%;
}

.supplier-modal-contact.hidden {
  display: none;
}

.supplier-config-stock-form {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: 10px;
  display: flex;
}

.supplier-info-tiltle {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.supplier-info-tiltle.split {
  margin-top: 20px;
}

.supplier-info-tiltle.subtitle {
  margin-bottom: 0;
}

.supplier-stock {
  background-image: linear-gradient(#fff, #fff);
  border: 1px solid #e9e9e9;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  margin-bottom: 20px;
  padding: 0 10px 20px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
  display: flex;
}

.supplier-stock.hidden {
  display: none;
}

.box-actions-sep-2 {
  float: left;
  border: 1px solid #e9e9e9;
  width: 1px;
  height: 100%;
  margin-top: 10px;
  margin-right: 0;
  display: block;
}

.text-block-42 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 13px;
}

.supplier-delai-form {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.supplier-config-stock-form-date {
  display: flex;
}

.supplier-table-header {
  background-color: #f8f5f5;
  flex-direction: row;
  display: flex;
}

.supplier-table-header.stock-table-header {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.day-checkbox {
  padding-right: 5px;
}

.slots-form-2 {
  float: none;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
}

.slots-form-2.stock {
  align-items: flex-start;
}

.slots-form-2.supplier-slot-form {
  flex: 1;
}

.supplier-new-contact {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 14px;
  display: flex;
}

.text-block-43 {
  flex: none;
  margin-right: 20px;
  font-size: 13px;
}

.supplier-config-contact {
  justify-content: flex-start;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
}

.div-block-57 {
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-top: 0;
  padding: 0;
  display: flex;
}

.div-block-58 {
  flex-direction: column;
  align-items: center;
  width: auto;
  display: block;
}

.supplier-table-content {
  max-height: 200px;
  overflow: scroll;
}

.supplier-table-row {
  border: 1px solid #fff;
  display: flex;
}

.link-to-filter {
  color: var(--black);
  background-color: #0000;
}

.supplier-config-delay-prep, .supplier-config-delay-expe, .supplier-config-delay-ferm {
  display: flex;
}

.supplier-table-content-link {
  float: left;
  border-right: 1px solid #e9e9e9;
  flex: 0 100px;
  justify-content: center;
  align-items: center;
  height: 60px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}

.supplier-table-content-link.col-1.table-cell, .supplier-table-content-link.col-1.cell {
  height: 60px;
}

.supplier-table-content-link.col-2 {
  flex-basis: 200px;
}

.supplier-table-content-link.col-2.table-cell, .supplier-table-content-link.col-2.cell {
  height: 60px;
}

.supplier-table-content-link.col-3 {
  flex: 1;
  height: 50px;
}

.supplier-table-content-link.col-3.cell {
  height: 60px;
}

.supplier-table-content-link.col-5 {
  flex: 0 0 200px;
}

.supplier-table-content-link.col-5.cell {
  height: 60px;
}

.supplier-table-content-link.col-6.cell {
  text-align: center;
  align-items: center;
  height: 60px;
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  display: flex;
  position: relative;
}

.supplier-table-content-link.col-last {
  flex: 0 30px;
}

.supplier-table-content-link.col-last.cell {
  color: #333;
  cursor: pointer;
  border-bottom: 1px solid #e9e9e9;
  height: 35px;
  text-decoration: none;
}

.supplier-table-content-link.col-last.cell:hover {
  color: #e8522d;
}

.supplier-table-content-link.col-last.header {
  color: #333;
  cursor: pointer;
  border-bottom: 0 solid #e9e9e9;
  height: 30px;
  text-decoration: none;
}

.supplier-table-content-link.col-last.header:hover {
  color: #e8522d;
}

.supplier-table-content-link.col-qty {
  flex-basis: 200px;
}

.supplier-table-content-link.sav-list-col-1 {
  flex-basis: 45px;
}

.supplier-table-content-link.sav-list-col-2 {
  flex-basis: 200px;
}

.supplier-table-content-link.sav-list-col-3 {
  flex: 0 0 150px;
}

.supplier-table-content-link.sav-list-col-4, .supplier-table-content-link.sav-list-col-5 {
  flex: 1;
}

.supplier-table-content-link.sav-list-col-6 {
  flex-basis: 100px;
}

.supplier-table-content-link.sav-list-col-last {
  flex-basis: 30px;
}

.supplier-table-content-link.sav-list-col-8 {
  flex-basis: 200px;
}

.supplier-table-content-link.col-store, .supplier-table-content-link.col-status {
  flex-basis: 150px;
}

.supplier-table-content-link.sav-list-col-neworder {
  flex-basis: 110px;
}

.supplier-table-content-link.stock-list-col {
  height: 25px;
}

.supplier-table-content-link.stock-list-col.stock-list-col-1, .supplier-table-content-link.stock-list-col.stock-list-col-2 {
  flex: 1;
}

.supplier-table-content-link.stock-list-col.stock-list-col-last {
  flex-shrink: 0;
  flex-basis: 30px;
}

.supplier-table-content-link.col-1 {
  flex: 0 auto;
  display: flex;
}

.supplier-table-content-link.col-1.cell {
  height: 60px;
}

.supplier-config-recap-rupture {
  flex-direction: column;
  height: 200px;
  margin-top: 10px;
  margin-left: 0;
  margin-right: 10px;
  display: block;
}

.supplier-config-recap-delai {
  height: 200px;
  margin-top: 10px;
  margin-left: 0;
}

.supplier-config-stock-content {
  flex-direction: column;
  align-items: flex-start;
  height: 80%;
  display: flex;
  overflow: scroll;
}

.supplier-config-stock-multiple {
  justify-content: flex-start;
  align-items: center;
  height: auto;
  margin-top: 0;
  padding: 0;
  display: flex;
}

.delete-button {
  color: #e8522d;
  background-color: #0000;
  margin-left: 0;
  margin-right: 10px;
  padding: 0;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.delete-button.date-type {
  padding-top: 2px;
}

.text-mul {
  justify-content: flex-start;
  align-items: center;
  width: 150px;
  display: flex;
}

.closed-date-row {
  justify-content: center;
  display: flex;
}

.div-block-60 {
  margin-top: 10px;
}

.text-block-44 {
  text-align: center;
  margin-right: 20px;
  padding-top: 3px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.closing-date {
  width: 125px;
  height: 25px;
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 20px;
  padding: 0 0 0 5px;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.closing-date.input-from {
  margin-left: 0;
}

.closing-date.retailer-config {
  margin-left: 20px;
  margin-right: 0;
}

.closing-date.retailer-config.form {
  margin-top: 5px;
  margin-left: 0;
  padding-top: 0;
}

.div-block-61, .closed-date-content {
  max-height: 100px;
  overflow: scroll;
}

.closed-header {
  justify-content: space-between;
  align-items: center;
  height: auto;
  margin-top: 0;
  padding: 0;
  display: flex;
}

.supplier-config-stock-rupture-partial {
  flex-direction: column;
  justify-content: space-around;
  width: 250px;
  display: flex;
  overflow: hidden;
}

.supplier-config-stock-rupture-temp-tab {
  border: 1px solid #000;
  min-width: 100px;
  max-width: 300px;
  height: 175px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 5px;
  overflow: scroll;
}

.div-block-62 {
  display: flex;
}

.sav-file {
  flex-direction: column;
  align-items: center;
  width: auto;
  display: flex;
}

.title-subtitle {
  margin-bottom: 10px;
  font-size: 11px;
}

.modal-container-incident {
  background-color: #fff;
  flex-direction: column;
  width: 650px;
  min-height: 100px;
  margin-top: 0;
  display: flex;
}

.modal-container-incident.hidden {
  display: none;
}

.sconf-warehouse-reception-capacity-list {
  flex-direction: column;
  margin-left: 72px;
  display: flex;
}

.sconf-warehouse-reception-capacity-row {
  align-items: center;
  margin-top: 4px;
  display: flex;
}

.sconf-warehouse-reception-capacity-row.hidden {
  display: none;
}

.sconf-warehouse-reception-capacity-input {
  max-width: 100px;
  margin-bottom: 0;
  margin-left: 10px;
}

.sconf-warehouse-reception-capacity-label {
  min-width: 350px;
  font-weight: 700;
}

.sconf-warehouse-reception-capacity-today {
  margin-left: 8px;
  font-weight: 700;
}

.set-tracking-modal-wrapper {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
}

.set-tracking-modal-wrapper.hidden {
  display: none;
}

.sav-modal-content-sub-text {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}

.div-block-63 {
  justify-content: center;
  display: flex;
}

.text-field-2 {
  max-width: 40vw;
}

.form-5 {
  justify-content: center;
  display: flex;
}

.sav-modal-link {
  color: var(--blue-link-color);
  cursor: pointer;
  position: absolute;
  inset: 0% 0% auto auto;
}

.sav-address-modal-wrapper {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
}

.sav-address-modal-wrapper.hidden {
  display: none;
}

.sav-address-modal-form {
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.sav-address-modal-form-section {
  flex-wrap: nowrap;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
}

.sav-address-modal-form-field {
  flex-direction: row;
  flex: 1;
  margin-right: 20px;
  display: flex;
}

.sav-address-modal-form-label {
  justify-content: flex-end;
  align-self: center;
  align-items: center;
  width: 80px;
  margin-right: 20px;
  display: flex;
}

.sav-address-modal-form-label.street-label {
  margin-right: 20px;
}

.sav-address-modal-form-input {
  max-width: 300px;
  height: 100%;
}

.sav-address-modal-form-input.zipcode {
  flex: 1;
}

.sav-address-modal-form-input.city {
  width: 100%;
}

.div-block-64 {
  flex-direction: row;
  flex: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
}

.supplier-stock-section {
  flex-flow: column;
  flex: 0 auto;
  order: -1;
  justify-content: flex-start;
  align-self: stretch;
  display: flex;
}

.supplier-stock-section.stock-file-upload-section {
  flex-direction: row;
  width: 100%;
  margin-top: 40px;
}

.col-6.stock-rupture-list {
  order: 0;
  margin-left: 117px;
  display: block;
}

.supplier-stock-upload-form {
  flex-direction: row;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.supplier-stock-upload-section {
  flex-direction: column;
  display: flex;
}

.form-block-5 {
  flex: 1;
  width: 116px;
  display: flex;
}

.problem-reassort-date {
  width: 180px;
  height: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  margin-right: -10px;
  padding-left: 5px;
  padding-right: 0;
  font-family: Roboto, sans-serif;
  font-size: 12px;
}

.icon-calendar2 {
  text-align: left;
  flex: 0 auto;
  margin-left: 10px;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.icon-calendar2.form {
  padding-top: 7px;
}

.body {
  display: block;
}

.inline-action-row {
  justify-content: flex-end;
  align-items: flex-end;
  padding-top: 10px;
  padding-bottom: 0;
  display: flex;
}

.div-block-65 {
  display: inline;
}

.bonretrait-info {
  float: right;
  clear: none;
}

.header-msg {
  color: red;
  text-align: center;
  font-weight: 700;
}

.from-date-to {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.form-block-6 {
  margin-bottom: 0;
}

.link-2 {
  text-decoration: none;
}

.div-block-66 {
  float: right;
  margin-left: 20px;
  font-family: "Idautomationhc39m code 39 barcode", sans-serif;
}

.div-block-67 {
  float: right;
  margin-top: 20px;
  display: block;
}

.text-block-45 {
  float: left;
}

.loyalty_card_div {
  float: right;
}

.loyalty_card_text {
  float: left;
}

.loyalty_barcode {
  float: right;
  margin-left: 10px;
}

.row-msg {
  border-bottom: 1px solid #e9e9e9;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  display: flex;
}

.row-msg.collect-limit {
  color: #fff;
  background-color: #bf0a3a;
}

.row-msg.pickup-limit {
  background-color: #eec416bf;
}

.row-msg.refund-row-msg {
  height: 40px;
}

.div-block-68 {
  flex: 1;
  width: 11%;
  height: 30px;
}

.table-row-msg {
  text-align: center;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 30px;
  display: flex;
}

.tab-cell {
  height: 60px;
}

.div-block-69, .order-id-barcode {
  margin-top: 20px;
}

.slots-form-2-copy {
  float: none;
  flex-direction: column;
  flex: 0 auto;
  justify-content: flex-start;
  align-items: stretch;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
}

.slots-form-2-copy.stock {
  align-items: flex-start;
}

.supplier-stock-history-wrapper {
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  align-self: auto;
  display: flex;
}

.supplier-stock-upload-table-wrapper {
  flex-direction: column;
  flex: 1;
  order: 1;
  justify-content: flex-start;
  align-self: auto;
  align-items: stretch;
  height: auto;
  margin-bottom: 0;
  display: flex;
}

.text-block-47 {
  text-align: center;
}

.sed-col-1, .sed-col-2 {
  width: 20%;
}

.sed-col-3, .sed-col-4, .sed-col-5 {
  width: 15%;
}

.sed-col-6 {
  width: 10%;
}

.sed-col-7 {
  width: 5%;
}

.sed-row {
  border-bottom: 1px solid #e9e9e9;
}

.image-3 {
  margin-bottom: 20px;
}

.image-4, .refund-modal {
  display: block;
}

.refund-modal-ldd-content {
  flex-direction: column;
  flex: 1;
  order: 1;
  justify-content: flex-start;
  align-self: auto;
  align-items: stretch;
  height: auto;
  margin-bottom: 0;
  display: flex;
}

.refund-table-header {
  border: 1px #000;
}

.refund-table-header.col-2 {
  justify-content: center;
  align-items: center;
  width: 50%;
  display: flex;
}

.refund-table-header.col-2.stock-date-header {
  width: 33%;
}

.refund-table-header.col-1 {
  order: 0;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30px;
  display: flex;
}

.refund-table-header.col-1.stock-filename-header {
  justify-content: center;
  width: 10%;
  margin-left: 0;
  margin-right: 0;
}

.refund-table-header.col-1.stock-filename-header.ean {
  width: 11%;
}

.refund-table-header.col-1.stock-filename-header.ean-header {
  width: 20%;
}

.refund-table-header.col-1.stock-filename-header.product-name-header {
  width: 40%;
}

.refund-table-header.col-1.stock-filename-header.reason-header, .refund-table-header.col-1.stock-filename-header.product-name-header-ldd {
  width: 20%;
}

.refund-table-header.col-1.stock-filename-header.ean-header-ldd {
  width: 15%;
}

.refund-table-header.col-3 {
  flex: 0 auto;
  margin-left: 0;
  padding-left: 0;
}

.refund-table-header.col-3.stock-time-header {
  width: 33%;
}

.refund-table-header-div {
  border: 1px #000;
}

.refund-table-header-div.col-2 {
  justify-content: center;
  align-items: center;
  width: 50%;
  display: flex;
}

.refund-table-header-div.col-2.stock-date-header {
  width: 33%;
}

.refund-table-header-div.col-1 {
  order: 0;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30px;
  display: flex;
}

.refund-table-header-div.col-1.stock-filename-header {
  width: 33%;
}

.refund-table-header-div.col-3 {
  flex: 0 auto;
  margin-left: 0;
  padding-left: 0;
}

.refund-table-header-div.col-3.stock-time-header {
  width: 33%;
}

.supplier-table-header-div-copy {
  border: 1px #000;
}

.supplier-table-header-div-copy.col-2 {
  justify-content: center;
  align-items: center;
  width: 50%;
  display: flex;
}

.supplier-table-header-div-copy.col-2.stock-date-header {
  width: 33%;
}

.supplier-table-header-div-copy.col-1 {
  order: 0;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30px;
  display: flex;
}

.supplier-table-header-div-copy.col-1.stock-filename-header {
  width: 33%;
}

.supplier-table-header-div-copy.col-3 {
  flex: 0 auto;
  margin-left: 0;
  padding-left: 0;
}

.supplier-table-header-div-copy.col-3.stock-time-header {
  width: 33%;
}

.refund-table-header-wrapper {
  background-color: #f8f5f5;
  flex-direction: row;
  display: flex;
}

.refund-table-header-wrapper.stock-table-header {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.refund-table-content {
  max-height: 200px;
  overflow: scroll;
}

.refund-table-row {
  border: 1px solid #fff;
  display: flex;
}

.refund-table-content-div.col-1.cell {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border-bottom: 1px solid #e9e9e9;
  border-left: 1px solid #e9e9e9;
  border-right: 1px solid #e9e9e9;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  display: flex;
}

.refund-table-content-div.col-1.cell.ss-cmd {
  width: 12%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
}

.refund-table-content-div.col-1.cell.sku {
  width: 12%;
}

.refund-table-content-div.col-1.cell.available-qty, .refund-table-content-div.col-1.cell.refund-qty {
  width: 10%;
}

.refund-table-content-div.col-1.cell.product-name {
  width: 40%;
}

.refund-table-content-div.col-1.cell.refund-reason, .refund-table-content-div.col-1.cell.ean {
  width: 20%;
}

.refund-table-content-div.col-1.cell.refund-reason-retrait {
  width: 100%;
}

.refund-table-content-div.col-1.cell.product-name-ldd {
  width: 20%;
}

.refund-table-content-div.col-1.cell.ean-ldd {
  width: 15%;
}

.refund-table-content-div.col-1.cell.unit-price, .refund-table-content-div.col-1.cell.maximum-refund-amount, .refund-table-content-div.col-1.cell.refund-amount {
  width: 10%;
}

.refund-table-content-div.col-2.cell {
  border: 1px solid #e9e9e9;
  border-top: 1px #000;
  border-left: 1px #000;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 35px;
  display: flex;
}

.refund-qty-input {
  object-fit: fill;
  margin-bottom: -16px;
  padding-bottom: 0;
}

.select-field-4 {
  object-fit: fill;
  margin-bottom: -12px;
  position: static;
}

.refund-reason {
  margin-bottom: -15px;
  padding-bottom: 0;
  display: block;
}

.item-fully-refund-wrapper {
  background-color: var(--background-login);
  text-align: center;
  flex: 0 auto;
  max-width: 100px;
  display: block;
}

.item-fully-refund-txt {
  background-color: var(--background-login);
  text-align: center;
  width: 100px;
}

.warning-message {
  color: red;
  text-align: center;
  flex: 1;
  display: block;
}

.form-block-7 {
  flex: 1;
}

.sav-line-msg-pack {
  display: block;
}

.refund_modal_ldd_wrapper {
  flex-direction: row;
  display: flex;
  position: static;
}

.refund-modal_ldd {
  flex: 1;
  display: block;
}

.recap-rupture-stock-wrapper {
  flex-direction: column;
  flex: 0 auto;
  order: 1;
  justify-content: flex-start;
  align-self: auto;
  align-items: stretch;
  height: auto;
  margin-bottom: 0;
  display: flex;
}

.supplier-recap-price {
  display: flex;
}

.supplier-recap-price.hidden {
  display: none;
}

.title-collect-voucher {
  text-align: left;
  margin-top: 54px;
  margin-left: 69px;
  font-size: 20px;
  font-weight: 700;
}

.info-container {
  border: 3px solid var(--dark-grey);
  border-radius: 6px;
  display: block;
}

.body-2 {
  object-fit: fill;
}

.infos-container {
  flex: 1;
  display: flex;
  position: relative;
}

.infos-container.discounts-retrait {
  padding-left: 20px;
}

.bon-ramasse-table-container {
  width: 100%;
  margin-bottom: 0;
  display: block;
}

.bon-ramasse-table {
  -webkit-text-fill-color: inherit;
  background-clip: border-box;
  border: 2px solid #b3b3b3;
  border-radius: 0;
  flex-flow: row;
  place-content: stretch space-between;
  align-items: stretch;
  margin-bottom: 15px;
  display: flex;
}

.img-container {
  flex: 0 auto;
  align-self: center;
  width: 12.5%;
  height: 100%;
}

.img-product-title {
  text-align: center;
}

.table-infos {
  flex: 1;
  display: flex;
  position: relative;
}

.table-infos.discounts-retrait {
  padding-left: 20px;
}

.ref-product-container {
  border: 0 solid #000;
  border-radius: 0;
  width: 12.5%;
  height: 100%;
  min-height: 100%;
}

.ean-product-container, .sku-product-container {
  width: 12.5%;
  height: 100%;
}

.libelle-product-container {
  flex-direction: column;
  flex: none;
  align-self: auto;
  width: 12.5%;
  height: 100%;
  min-height: 100%;
  display: block;
  overflow: visible;
}

.qty-product-container, .unit-price-container {
  width: 12.5%;
  height: 100%;
}

.ref-product-title {
  text-align: center;
}

.ref-product-value {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
}

.ean-product-title {
  text-align: center;
}

.ean-product-value {
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
}

.sku-product-title {
  text-align: center;
}

.sku-product-value {
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
}

.libelle-product-title {
  text-align: center;
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;
}

.libelle-product-value {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
}

.qty-product-title, .qty-product-value, .unit-price-title, .unit-price-value {
  text-align: center;
  padding-left: 2px;
  padding-right: 2px;
}

.order-list-container {
  width: 12.5%;
}

.order-list-title {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  overflow: auto;
}

.order-list-value {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
}

.img-product-value {
  background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/background-image.svg");
  background-position: 0 0;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.bon-ramasse-modal {
  float: none;
  background-color: var(--white);
  display: none;
  position: absolute;
  inset: 0%;
  overflow: visible;
}

.remember-section {
  text-align: center;
  background-color: #ec0e0e;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-bottom: 10px;
  display: flex;
}

.action-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.supplier-tracking-upload-form {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 40px;
  display: flex;
}

.form-6 {
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  height: 40px;
  margin-bottom: 0;
  display: none;
}

.supplier-input-tracking {
  align-self: center;
  height: 40px;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.supplier-tracking-button {
  height: 40px;
}

.refund-amount-input {
  object-fit: fill;
  margin-bottom: -16px;
  padding-bottom: 0;
}

.form-buttons {
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.order-search-form-bottom {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  min-width: 30%;
  margin-top: 10px;
}

.order-search-form-bottom.sav-existing-file, .order-search-form-bottom.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.order-search-form {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.order-search-form-block {
  text-align: left;
  flex: 1;
}

.order-search-form-line {
  grid-column-gap: 4%;
  grid-row-gap: 4%;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  display: block;
}

.order-search-form-line.sav-existing-file, .order-search-form-line.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.order-search-form-row {
  flex: 0 auto;
  order: 0;
  width: 30%;
  min-width: 30%;
  max-width: 30%;
  display: block;
}

.order-search-form-row.client-row {
  max-width: 35%;
  display: block;
}

.order-search-form-row.delivery-row, .order-search-form-row.status-row {
  flex: 0 auto;
  display: block;
}

.order-search-form-row.store-types-row {
  flex: 0 auto;
  width: 30%;
  display: block;
}

.order-search-form-row.export-row {
  display: none;
}

.order-search-form-row.store-row, .order-search-form-row.multiple-store-row, .order-search-form-row.multiple-store-row-copy, .order-search-form-row.ramasse-type-row {
  flex: 0 auto;
  width: 30%;
  display: block;
}

.sav-form-row-copy {
  flex: 1;
  order: 0;
  display: block;
}

.search-status-input, .search-batch-input, .search-delivery-input, .search-store-types-input {
  margin-bottom: 0;
  display: block;
}

.search-export-input {
  display: block;
}

.search-action-row {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: row;
  order: 1;
  justify-content: center;
  align-self: auto;
  align-items: flex-end;
  min-height: 100%;
  display: flex;
}

.order-search-form-date-range {
  flex: 1;
  width: 30%;
  min-width: 30%;
  max-width: 30%;
}

.order-search-date-label {
  padding-bottom: 1px;
  padding-left: 5px;
  font-weight: 500;
}

.order-search-form-date-wrapper {
  align-items: center;
  display: flex;
}

.order-search-form-date-from {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.order-search-form-date-to {
  margin-bottom: 0;
  margin-left: 10px;
}

.order-export-form {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.order-export-form-block {
  flex: 1;
}

.order-export-form-bottom {
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  padding-right: 0;
  display: flex;
}

.order-export-form-bottom.sav-existing-file, .order-export-form-bottom.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.order-export-form-line {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: flex-end;
  width: 35%;
  display: flex;
}

.order-export-form-line.sav-existing-file, .order-export-form-line.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.order-export-form-row {
  flex: 1;
  order: 0;
  display: block;
}

.order-export-form-row.client-row, .order-export-form-row.delivery-row {
  display: block;
}

.order-export-form-row.export-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  object-fit: fill;
  flex-flow: column-reverse;
  flex: 1;
  align-self: auto;
  max-width: 35vw;
  display: flex;
}

.export-action-row {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-flow: row;
  flex: 0 auto;
  order: 1;
  justify-content: center;
  align-self: auto;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.order-export-input {
  height: 35px;
  margin-bottom: 0;
  display: block;
}

.order-export-button {
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #e8522d;
  border-radius: 4px;
  justify-content: center;
  align-self: center;
  align-items: center;
  min-height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  text-decoration: none;
  display: flex;
}

.order-export-button.reset-input {
  justify-content: center;
  align-items: center;
  width: 35px;
  font-family: Fontawesome webfont, sans-serif;
}

.order-export-button.search-button {
  text-transform: uppercase;
  margin-left: 20px;
}

.order-export-button.action-button.hidden {
  display: none;
}

.order-export-button.action-button.force-collect {
  margin-right: 10px;
}

.order-export-button.error-button {
  width: 200px;
}

.order-export-button.z-to-ship.inactive, .order-export-button.disabled {
  background-color: #d1d1d1;
}

.order-export-button.jar-button-refund {
  margin-bottom: 10px;
}

.order-export-button.jar-button-refund-shipping, .order-export-button.jar-button-spacer {
  margin-right: 20px;
}

.order-export-button.jar-button-spacer.gm-color {
  background-color: var(--bordergreen);
}

.order-export-button.product-qty-label {
  background-color: #2d9de8;
}

.order-export-button.button-cc-bp-refund {
  margin-left: 20px;
}

.order-export-button.button-cc-bp-submit, .order-export-button.button-bonretrait-print {
  margin-right: 20px;
}

.order-export-button.jar-dl-file {
  background-color: #4884b4;
}

.order-export-button.jar-upload-file {
  background-color: #48b466;
  margin-left: 20px;
}

.order-export-button.jar-button-invoice {
  margin-bottom: 10px;
}

.order-export-button.jar-button-red {
  margin-top: 10px;
  margin-bottom: 10px;
}

.order-export-button.jar-button-green {
  background-color: #48b466;
  margin-top: 10px;
  margin-bottom: 10px;
}

.order-export-button.jar-button-blue {
  background-color: #4985f0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.order-export-button.rebound-filter {
  margin-left: 20px;
}

.order-export-button.export-button {
  flex-flow: row;
  flex: 0 auto;
  order: 0;
  place-content: center;
  align-self: auto;
  align-items: center;
  width: 100px;
  margin-top: 0;
  margin-left: 25px;
  margin-right: 20px;
  display: flex;
}

.order-export-button.export-button.gam-color {
  background-color: var(--bordergreen);
}

.order-action-form {
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
}

.order-action-form-block {
  text-align: center;
  flex: 1;
}

.action-form-buttons {
  flex-flow: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  min-height: 100%;
  display: flex;
}

.order-action-row {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  flex-flow: row;
  flex: 1;
  order: 1;
  justify-content: space-between;
  align-self: auto;
  align-items: flex-end;
  min-height: 100%;
  padding-left: 0;
  display: flex;
}

.order-action-form-buttons {
  flex-flow: row;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  min-height: 100%;
  display: block;
}

.order-action-form-line {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.order-action-form-line.sav-existing-file, .order-action-form-line.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.order-action-form-container {
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: flex-end;
  margin-bottom: 0;
  display: flex;
}

.order-action-input {
  align-self: center;
  height: 35px;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 0;
}

.order-action-upload-form {
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-self: center;
  align-items: flex-end;
  display: flex;
}

.order-action-bon-ramasse-modal {
  float: none;
  background-color: var(--white);
  display: block;
  position: absolute;
  inset: 0%;
  overflow: visible;
}

.order-search-form-lines {
  grid-column-gap: 5%;
  grid-row-gap: 10px;
  flex-flow: wrap;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.order-search-form-lines.sav-existing-file, .order-search-form-lines.sav-upload-file {
  align-items: center;
  margin-bottom: 10px;
}

.search-store-input {
  margin-bottom: 0;
  display: block;
}

.multiple-select-container {
  flex-flow: column;
  display: flex;
}

.multiple-select-label {
  padding-bottom: 1px;
  padding-left: 5px;
}

.multiple-select-dropdown {
  width: 100%;
  position: relative;
}

.multiple-select-dropdown-toggle {
  cursor: pointer;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 8px 12px;
  overflow: hidden;
}

.multiple-select-dropdown-content {
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
  min-width: 100%;
  max-width: 100%;
  max-height: 50vh;
  position: absolute;
  overflow-y: auto;
}

.multiple-select-checkbox-container {
  cursor: pointer;
  margin-bottom: 0;
  padding: 8px 12px 8px 35px;
  font-weight: 400;
  display: block;
  position: relative;
}

.multiple-select-checkbox-container:hover {
  background-color: #f0f0f0;
}

.multiple-select-option-label {
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.multiple-select-input {
  opacity: 0;
  cursor: pointer;
  display: none;
  position: absolute;
}

.multiple-select-checkmark {
  background-color: #fff;
  border: 1px solid #ccc;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 10px;
  left: 10px;
}

.table-container-copy {
  border: 1px solid #e9e9e9;
  margin-top: 10px;
  font-size: 14px;
}

.table-container-copy.table-container-stock {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.table-container-copy.lad-tab {
  display: none;
}

.table-wrapper.table-container-stock {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.log-tab-title {
  border-bottom: 1px solid #ccc;
  margin: 10px;
  padding-bottom: 5px;
}

.log-tab-lines {
  border: 1px solid #ccc;
  margin: 15px 10px 10px;
}

.log-tab-line {
  display: flex;
}

.log-tab-line-id.log-tab-line-cell {
  background-color: #bdbdbd;
  padding: 2px 40px 2px 5px;
}

.log-tab-line-status.log-tab-line-cell {
  flex: 1;
  padding: 2px 10px;
}

.log-tab-line-date.log-tab-line-cell {
  background-color: #ddd;
  padding: 2px 20px 2px 10px;
}

.information-tab-container {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.information-tab-action-bar {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.information-tab-infos {
  border: 1px solid #ccc;
}

.information-tab-billing-address, .information-tab-shipping-address {
  border: 1px solid #ccc;
  flex: 1;
}

.information-tab-address {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.information-tab-items {
  border: 1px solid #ccc;
}

.information-tab-price, .information-tab-promos {
  border: 1px solid #ccc;
  align-self: flex-end;
  width: 60%;
  padding: 10px;
}

.information-tab-neworder {
  border: 1px solid #ccc;
}

.information-tab-infos-desc-col-1 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  flex: 1;
  padding: 10px;
  display: flex;
}

.information-tab-infos-header {
  background-color: #eee;
  padding: 5px;
}

.information-tab-infos-desc {
  display: flex;
}

.information-tab-infos-desc-col-2 {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  flex: 1;
  padding: 10px;
  display: flex;
}

.information-tab-infos-desc-neworder {
  align-items: center;
  display: flex;
}

.information-tab-infos-desc-neworder-label {
  margin-right: 5px;
}

.information-tab-infos-desc-neworder-link {
  cursor: pointer;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  transition: background-color .2s;
  display: flex;
}

.information-tab-infos-desc-neworder-link:hover {
  background-color: #eee;
}

.information-tab-billing-address-header {
  background-color: #eee;
  padding: 5px;
}

.information-tab-shipping-address-header {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  background-color: #eee;
  justify-content: space-between;
  padding: 5px;
  display: flex;
}

.information-tab-billing-address-content, .information-tab-shipping-address-content {
  flex-flow: column;
  padding: 10px;
  display: flex;
}

.information-tab-shipping-address-content-copy {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  padding: 10px;
  display: flex;
}

.information-tab-items-title {
  padding: 10px;
}

.information-tab-items-title-text {
  margin-top: 0;
  margin-bottom: 0;
}

.information-tab-items-table-header {
  background-color: #eee;
  border-top: 1px solid #ccc;
  display: flex;
}

.information-tab-items-table-line {
  border-top: 1px solid #ccc;
  display: flex;
}

.information-tab-items-table-header-col, .information-tab-items-table-line-col {
  flex: 1 0 0;
  padding: 10px;
  overflow: hidden;
}

.information-tab-price-line, .information-tab-promos-line {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  display: flex;
}

.information-tab-neworder-header {
  background-color: #eee;
  padding: 5px;
}

.information-tab-neworder-content {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  align-items: baseline;
  padding: 10px;
  display: flex;
}

.information-tab-neworder-line-link {
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  padding-left: 5px;
  padding-right: 5px;
  transition: background-color .2s;
  display: flex;
}

.information-tab-neworder-line-link:hover {
  background-color: #eee;
}

.information-tab-items-table-header-col-checkbox, .information-tab-items-table-line-col-checkbox {
  flex: 0 0 40px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  overflow: hidden;
}

.dashboard-container {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #fff;
  flex-flow: column;
  padding: 10px 40px 30px;
  display: flex;
}

.dashboard-header {
  margin-left: 40px;
  margin-right: 40px;
}

.dashboard-title {
  margin-top: 0;
}

.dashboard-header-footer {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  display: flex;
}

.dashboard-block {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-radius: 2px;
  flex-flow: column;
  flex: 1 0 0;
  padding: 15px 20px;
  display: flex;
  box-shadow: 0 3px 5px #0003;
}

.dashboard-header-bar {
  border: 1px solid #dedede;
  flex: 50px;
}

.dashboard-header-periodicity {
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  cursor: pointer;
  border-radius: 3px;
  align-items: center;
  padding: 2px 5px;
  display: flex;
  position: relative;
}

.dashboard-header-export-button {
  cursor: pointer;
  background-color: #284e8f;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 3px;
  display: flex;
}

.dashboard-header-separator {
  border: 1px solid #dedede;
  height: 30px;
}

.dashboard-header-periodicity-arrow {
  color: #284e8f;
  font-family: Fontawesome webfont, sans-serif;
}

.dashboard-header-export-icon {
  color: #fff;
  justify-content: center;
  align-items: center;
  font-family: Fontawesome webfont, sans-serif;
}

.dashboard-header-last-update {
  color: #777;
  font-style: italic;
}

.dashboard-header-periodicity-dropdown {
  background-color: #fff;
  border: 1px solid #dedede;
  max-height: 200px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
}

.dashboard-header-periodicity-dropdown-option {
  padding: 5px;
}

.dashboard-header-periodicity-dropdown-option:hover {
  background-color: #f7f7f7;
}

.dashboard-block-title {
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-block-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  height: 100%;
  display: flex;
}

.dashboard-info-box {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  border: 2px solid #284e8f;
  border-radius: 3px;
  flex: 1;
  margin-left: 20px;
  padding: 5px;
  position: relative;
}

.dashboard-info-box-icon-wrapper {
  background-color: #284e8f;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.dashboard-info-box-content {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  margin-left: 20px;
  display: flex;
}

.dashboard-content {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  display: flex;
}

.dashboard-info-box-number {
  color: #284e8f;
  font-size: 20px;
  font-weight: 700;
}

.dashboard-info-box-text {
  font-size: 12px;
}

.dashboard-content-body {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: flex-start;
  display: flex;
}

.dashboard-content-head {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.dashboard-content-body-left {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 33%;
  display: flex;
}

.dashboard-content-body-right {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  flex: 1;
  display: flex;
}

.dashboard-block-content-line {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  align-items: stretch;
  height: 100%;
  display: flex;
}

.dashboard-content-body-right-head {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.dashboard-delay-section-title {
  margin-top: 0;
}

.dashboard-delay-tables-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: flex-start;
  display: flex;
}

.dashboard-synthese-icon-wrapper {
  aspect-ratio: 1;
  background-color: #284e8f;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  min-width: 60px;
  height: 100%;
  min-height: 60px;
  max-height: 100px;
  padding: 6px;
  display: flex;
}

.dashboard-synthese-block-content {
  flex-flow: column;
  flex: 1;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.dashboard-info-box-icon {
  color: #fff;
  font-family: Fontawesome webfont, sans-serif;
  font-size: 20px;
}

.dashboard-synthese-icon {
  width: 100%;
  height: 100%;
}

.dashboard-synthese-block-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  height: 100%;
  display: flex;
}

.dashboard-synthese-title {
  margin: 0;
}

.dashboard-synthese-block-footer {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

.dashboard-synthese-link {
  color: #6084c0;
  cursor: pointer;
  text-decoration: underline;
}

.dashboard-synthese-number {
  white-space: nowrap;
  margin: 0;
  font-size: 22px;
  font-weight: 700;
}

.dashboard-delay-table {
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  flex: 1;
}

.dashboard-delay-table-title {
  color: #fff;
  background-color: #284e8f;
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px 10px;
}

.dashboard-delay-table-header {
  background-color: #c1d2eb;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.dashboard-delay-table-header-col {
  flex: 1 0 0;
  min-width: 0;
}

.dashboard-delay-table-header-col.dashboard-delay-table-header-actions {
  text-align: right;
}

.dashboard-delay-table-header-text {
  padding: 5px 20px;
}

.dashboard-delay-table-content {
  max-height: 50vh;
  overflow: auto;
}

.dashboard-delay-table-line {
  border-top: 1px solid #dedede;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.dashboard-delay-table-col {
  flex-flow: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  display: flex;
}

.dashboard-delay-table-col.dashboard-delay-table-actions {
  align-items: flex-end;
}

.dashboard-delay-table-col-text {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  display: flex;
}

.dashboard-delay-table-col-text.dashboard-delay-table-col-button {
  cursor: pointer;
  border-radius: 15px;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  transition: background-color .2s;
  display: inline-flex;
}

.dashboard-delay-table-col-text.dashboard-delay-table-col-button:hover {
  background-color: #f0f0f0;
}

.dashboard-donut-title {
  text-align: center;
}

.dashboard-donut-title-wrapper {
  border-top: 1px solid #dedede;
  padding-left: 10px;
  padding-right: 10px;
}

.dashboard-donut-container {
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.dashboard-donut-placeholder {
  width: 200px;
  height: 200px;
}

.dashboard-delay-table-col-button {
  cursor: pointer;
  border-radius: 15px;
  width: 28px;
  height: 28px;
  margin-right: 10px;
  padding-left: 5px;
  padding-right: 5px;
  transition: background-color .2s;
  display: inline-flex;
}

.dashboard-delay-table-col-button:hover {
  background-color: #f0f0f0;
}

.dashboard-orders-container {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  background-color: #fff;
  flex-flow: column;
  padding: 10px;
  display: flex;
}

.dashboard-orders-header {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: row;
  align-items: center;
  display: flex;
}

.dashboard-orders-back-button-title {
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-orders-title-block {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  border-radius: 2px;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  display: flex;
  box-shadow: 0 3px 5px #0003;
}

.dashboard-orders-title-left-part {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.dashboard-orders-title-icon-wrapper {
  background-color: #284e8f;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  padding: 5px;
  display: flex;
}

.dashboard-orders-title-text {
  margin-top: 0;
  margin-bottom: 0;
}

.dashboard-orders-title-icon {
  width: 100%;
  height: 100%;
}

.dashboard-orders-separator {
  border: 1px solid #d5dce9;
}

.dashboard-orders-back-button {
  cursor: pointer;
  background-color: #284e8f;
  border-radius: 3px;
  width: 24px;
  height: 24px;
}

.dashboard-orders-table-header {
  background-color: #284e8f;
  display: flex;
}

.dashboard-orders-table-line {
  border-bottom: 1px solid #dedede;
  display: flex;
}

.dashboard-orders-table-header-col {
  flex: 1 1 0;
  min-width: 0;
  padding: 6px;
}

.dashboard-orders-table-header-col-text {
  color: #fff;
}

.dashboard-orders-table-col {
  color: #21375e;
  flex: 1 1 0;
  min-width: 0;
  padding: 6px;
}

.dashboard-orders-footer {
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.dashboard-orders-footer-separator {
  border: 2px solid #284e8f;
  border-radius: 3px;
}

.dashboard-orders-rows {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  display: flex;
}

.dashboard-orders-pagination {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;
  display: flex;
}

.dashboard-orders-rows-selector {
  border: 1px solid #284e8f;
  border-radius: 2px;
  width: 90px;
}

.dashboard-orders-pagination-left, .dashboard-orders-pagination-right {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
}

.dashboard-orders-pagination-arrow-icon {
  width: 100%;
  height: 100%;
}

.dashboard-orders-pagination-input {
  border: 1px solid #284e8f;
  border-radius: 2px;
  width: 40px;
}

.dashboard-orders-rows-selector-placeholder {
  padding-left: 3px;
}

.bon-ramasse-clients-section {
  margin-left: 10px;
  margin-right: 10px;
}

.bon-ramasse-clients-names {
  text-align: left;
  margin-top: 0;
  margin-left: 0;
  font-weight: 400;
}

.help-button {
  cursor: help;
  background-color: #284e8f;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
  display: inline-flex;
  position: relative;
  box-shadow: 0 2px 3px #0003;
}

.tooltip {
  cursor: auto;
  background-color: #f0f0f0;
  border-radius: 5px;
  width: max-content;
  max-width: 400px;
  padding: 4px 8px;
  display: none;
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translate(-50%);
  box-shadow: 0 2px 5px #0003;
}

.help-button-text {
  color: #fff;
  text-align: center;
  width: 50%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-decoration: none;
}

.help-button-wrapper {
  margin-left: 5px;
  margin-right: 5px;
  display: inline-block;
  position: relative;
}

.help-button-text-placeholder {
  margin-top: 100px;
  margin-left: 5px;
  display: inline-block;
}

.tooltip-arrow {
  background-color: #f0f0f0;
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(0, 50%)translate(-50%)rotate(45deg);
  box-shadow: 3px 3px 5px -2px #0003;
}

.tooltip-content {
  color: #333;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
}

.information-tab-shipping-address-edit-button {
  color: #1a0dab;
  cursor: pointer;
}

.modal {
  z-index: 100000;
  background-color: #57575782;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0;
}

.modal.hidden {
  display: none;
}

.information-tab-address-modal-text {
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: block;
}

.information-tab-address-modal-title {
  font-size: 20px;
  font-weight: 700;
}

.dashboard-orders-type-filter-select {
  background-color: #f5f5f5;
  border: 1px solid #284e8f;
  border-radius: 3px;
  padding: 5px;
}

.dashboard-orders-filters {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  justify-content: space-between;
  display: flex;
}

.dashboard-orders-type-filter, .dashboard-orders-filtered-total {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  align-items: center;
  display: flex;
}

.dashboard-orders-filtered-total-value {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
}

.list.dashboard-contact-information {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  margin-bottom: 0;
  display: flex;
}

.dashboard-contact-information-schedule {
  margin-top: -10px;
  font-weight: 700;
}

.dashboard-header-contact-button {
  cursor: pointer;
  background-color: #284e8f;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding: 3px;
  display: flex;
}

.search-ramasse-type-input {
  margin-bottom: 0;
  display: block;
}

.order-delivery-note {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: column;
  padding: 40px;
  display: flex;
}

.order-delivery-note-title {
  font-size: 28px;
  font-weight: 700;
}

.order-delivery-note-infos {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  display: flex;
}

.order-delivery-note-items {
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  width: 600px;
}

.order-delivery-note-logo {
  margin-left: 10px;
}

.order-delivery-note-logo-image {
  max-height: 70px;
}

.order-delivery-note-infos-labels {
  flex-flow: column;
  align-items: flex-end;
}

.order-delivery-note-infos-content {
  flex-flow: column;
}

.order-delivery-note-infos-line {
  flex-flow: row;
  display: flex;
}

.order-delivery-note-infos-label {
  flex: 0 0 100px;
  justify-content: flex-end;
  font-weight: 700;
  display: flex;
}

.order-delivery-note-infos-value {
  flex: 1;
  margin-left: 20px;
}

.order-delivery-note-items-header {
  border-bottom: 1px solid #000;
  flex-flow: row;
  font-weight: 700;
  display: flex;
}

.order-delivery-note-items-line {
  border-bottom: 1px solid #000;
  display: flex;
}

.order-delivery-note-items-cell {
  border-left: 1px solid #000;
  flex-flow: column;
  flex: 0 0 20%;
  justify-content: center;
  padding: 5px;
  display: flex;
}

.order-delivery-note-items-cell.order-delivery-note-item-quantity {
  flex: 0 0 20%;
}

.order-delivery-note-items-cell.order-delivery-note-item-label {
  flex: 0 0 60%;
}

.order-delivery-note-items-cell.order-delivery-note-item-reference {
  flex: 0 0 20%;
}

.order-delivery-note-container {
  justify-content: center;
  height: 100vh;
  display: flex;
}

.order-delivery-notes-wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.order-search-form-selector-label {
  padding-bottom: 1px;
  padding-left: 5px;
  font-weight: 500;
}

.order-search-form-selector, .order-search-form-selector-input {
  margin-bottom: 0;
}

.order-search-form-text-input-label {
  padding-bottom: 1px;
  padding-left: 5px;
  font-weight: 500;
}

.order-search-form-text-input-input {
  border-radius: 5px;
  margin-bottom: 0;
}

.order-search-form-date-field-wrapper {
  align-items: center;
  display: flex;
}

.order-search-form-date-field-label {
  padding-bottom: 1px;
  padding-left: 5px;
  font-weight: 500;
}

.order-search-form-date-field-from {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
}

.order-search-form-date-field-to {
  margin-bottom: 0;
  margin-left: 10px;
}

.loader-card {
  text-align: center;
  background-color: #fff;
  border-radius: 7px;
  padding: 15px;
}

.loader-text {
  text-align: center;
  margin-top: 10px;
  font-size: 22px;
}

.badorder-block-title {
  background-color: #eee;
  padding: 5px;
  font-weight: 700;
}

.badorder-block-content {
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  flex-flow: column;
  padding: 5px;
  display: flex;
}

.badorder-info-blocks {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-flow: row;
  display: flex;
}

.badorder-reason-content, .badorder-raw-data-content {
  margin-top: 0;
  margin-bottom: 0;
  padding: 5px;
}

.badorder-special-textarea {
  min-height: 800px;
}

.badorders-action-buttons {
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  display: flex;
  position: absolute;
  right: 0;
}

.order-carrier-input {
  height: 35px;
  margin-bottom: 0;
  display: block;
}

.supplier-carrier-block {
  flex: 1;
  width: 50%;
  margin-bottom: 0;
  display: block;
}

.information-tab-action-bar {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.refund-items-table-wrapper {
  flex-direction: row;
  display: flex;
  position: static;
}

.refund-items-table {
  flex: 1;
  display: block;
}

.refund-items-table-content {
  flex-direction: column;
  flex: 1;
  order: 1;
  justify-content: flex-start;
  align-self: auto;
  align-items: stretch;
  height: auto;
  margin-bottom: 0;
  display: flex;
}

.refund-tab-items-table-line-selector-col {
  flex-flow: column;
  flex: none;
  align-self: auto;
  width: 20%;
  padding: 0;
  display: block;
  overflow: hidden;
}

.refund-selector {
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  display: block;
}

.refund-tab-items-table-header-selector-col {
  text-align: center;
  width: 20%;
  padding: 10px;
  overflow: hidden;
}

.refund-selector-wrapper {
  flex-flow: column;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
}

.refund-selector-content {
  width: 100%;
  height: 100%;
}

.refund-tab-items-table-header-col {
  flex: 1 0 0;
  padding: 10px 0;
  overflow: hidden;
}

.refund-tab-items-table-header-col-label {
  text-align: center;
}

.refund-tab-items-table-header {
  background-color: #eee;
  border-top: 1px solid #ccc;
  display: flex;
}

.refund-tab-items-table-line {
  border-top: 1px solid #ccc;
  display: flex;
}

.refund-tab-items-table-line-col {
  text-align: center;
  flex: 1 0 0;
  padding: 10px 0;
  overflow: hidden;
}

.refund-tab-items-table-line-col-content {
  text-align: center;
  flex-flow: column;
  justify-content: center;
  height: 100%;
  display: flex;
}

.refund-tab-items-table-line-input-col {
  text-align: center;
  flex: 1 0 0;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  overflow: hidden;
}

.refund-tab-input-wrapper {
  flex-flow: row;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  display: block;
}

.refund-tab-input-content {
  width: 100%;
  height: 100%;
}

.refund-tab-input {
  text-align: center;
  object-fit: fill;
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
}

.dashboard-content-filters {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

@media screen and (max-width: 991px) {
  .content {
    padding-left: 0;
    padding-right: 0;
  }

  .table-head-col.sav-list-col-5 {
    height: 60px;
  }

  .table-head-col.sav-list-col-5.height {
    height: 80px;
  }

  .table-head-col.sav-list-col-9 {
    height: 60px;
  }

  .table-head-col.sav-list-col-9.height {
    height: 80px;
  }

  .jar-button {
    margin-left: 10px;
    margin-right: 10px;
  }

  .modal-container {
    width: 100%;
  }

  .jar-button-2 {
    margin-left: 10px;
    margin-right: 10px;
  }

  .modal-container-2, .modal-container-incident {
    width: 100%;
  }

  .order-export-button {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 767px) {
  .sav-address-modal-form-section {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 479px) {
  .header-action {
    display: none;
  }
}


@font-face {
  font-family: 'Fontawesome webfont';
  src: url('../fonts/fontawesome-webfont.woff2') format('woff2'), url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Fa brands 400';
  src: url('../fonts/fa-brands-400.eot') format('embedded-opentype'), url('../fonts/fa-brands-400.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Fa solid 900';
  src: url('../fonts/fa-solid-900.woff') format('woff'), url('../fonts/fa-solid-900.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Idautomationhc39m code 39 barcode';
  src: url('../fonts/IDAutomationHC39M Code 39 Barcode.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Fa 400';
  src: url('../images/fa-regular-400.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}