
@media print {
  /* https://stackoverflow.com/questions/1960939/disabling-browser-print-options-headers-footers-margins-from-page */
  @page
  {
    size:  auto;   /* auto is the initial value */
    margin: 0mm;  /* this affects the margin in the printer settings */
  }

  /* general hide */
  .header-container { display: none !important; }
  .ruk-top-block, .content { padding: 0 !important; margin: 0 !important; }
  .ruk-top-block { border: none !important; }

  html, body, .container { min-height: auto !important;  height: auto !important; }

  body { padding: 15px !important; }

  /* click&collect */
  .button-wrapper,
  .assign-block,
  .submit-block { display: none !important; }

  /* .product-qty-input-col { visibility: hidden !important; width: 10% !important; } */
  .product-qty-input-wrapper, .product-qty-label, .product-qty-total { display: none !important; }
  .product-header .product-qty-input-title-wrapper { display: none !important; }
  .content .product-qty-input-col {
    width: 100px;
    flex-basis: 100px;
    -webkit-flex-basis: 100px;
    -ms-flex-preferred-size: 100px;
  }
  .products-final-line .checkbox, .checkbox-wrapper { display: block !important; }
  .product-qty-input-title-wrapper { display: none !important; }
  .pagebreak { page-break-before: always; }
  .pagebreakavoid { page-break-inside: avoid; }
}

.multiple-select-input:checked + .multiple-select-checkmark:after {
  content: "";
  position: absolute;
  display: block;
  left: 4px;
  top: 0;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.multiple-select-dropdown-toggle:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 5px;
  width: 5px;
  height: 5px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

html {
  scroll-behavior: smooth
}